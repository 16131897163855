import React from 'react'
import { Menu } from '../menu'

import logo from '../../img/logo/logo-large-latest.svg'
import styles from './styles.module.scss'

export const Header = () => {
    let portions = ['/', '/program', '/application']

    return (
        <header className={`${portions.includes(window.location.pathname) && 'header-fixed'}`}>
            <a href="/"><img class="logo-brand" src={logo} /></a>
            <nav class="topnav-right">
                
                <Menu />
                <a href="/donate"><div class="button_slide slide_right slide_transparent">Donate</div></a>
                <a href="/joinus"><div class="button_slide slide_right slide_transparent">Join us</div></a>
                
            </nav>
        </header>
    )
}
import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import { PayPalScriptProvider } from '@paypal/react-paypal-js'

import { routes } from './routes'
import { Header } from './components/header'
import { Footer } from './components/footer'

import './jquery'
import './utils/cursor'

const App = () => {
  return (<>
  <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_ID,  'currency': 'EUR' }}>
    <div class="custom-cursor" />
    <Header />
    <Routes>
      {routes.map(({ exact, path, element: Comp }) => (
        <Route path={path} exact={exact} key={path} element={<Comp />} />
      ))}
    </Routes>
    <Footer />
  </PayPalScriptProvider>
  </>)
}

export default App;

import React, { useEffect, useState } from 'react'
import Tilt from 'react-parallax-tilt'
import $ from 'jquery'

const checkPass = (pass) => {
  if (pass === 'vertical21-22resident') {
    return true
  } else {
    return false
  }
}

export const ClassesC4 = () => {
  const [viewing, setViewing] = useState(false);
  const [context, setContext] = useState(null);
  const [content, setContent] = useState(null);

  const handleViewClass = (event) => {
    let context = $(event.currentTarget).attr('data-lightbox-type');
    let content = $(event.currentTarget).attr('data-lightbox-content');

    setContext(context);
    setContent(content);
    setViewing(true);
  }

  useEffect(() => {
    if (viewing === true) {
      handlePassClass()
    }
  }, [viewing])

  const handlePassClass = (event) => {
    console.log(viewing)

    console.log('clicked');
    $('.lightbox').fadeToggle('fast');
  
    // console.log(event);
    if (context == 'video') {
      $('.lightbox-column').append(`
          <div class="lightbox-video">
          <iframe src="${content}" frameborder="0" allowfullscreen> </iframe>
          </div>
      `);
    } else if (context == 'image') {
      $('.lightbox-column').append(`
          <img src="${content}" class="img-" frameborder="0" allowfullscreen>
      `);
    }
  }

    return (<>

      {viewing && (
            <div class="lightbox" style={{ zIndex: 9999 }}>
            <a href="#lightbox" class="lightbox-close lightbox-toggle" onClick={() => setViewing(false)}>X</a>
            <div class="lightbox-container">
              <div class="row">
                <div class="col-sm-12 lightbox-column">
                  
                </div>
              </div>
            </div>
          </div>
      )}

        <section id="main-alt-classes">

  <div class="container-classes-top">
    <div class="header-page w-50">
      <h1>Residency </h1>
      <h2>Classes</h2>
    </div>
    <div class="cohort-title">

      <a href="/classes" class="cohort-item-1"><h3>C1</h3></a>
      <a href="/classes-c2" class="cohort-item-2"><h3>C2</h3></a>
      <a href="/classes-c3" class="cohort-item-2"><h3>C3</h3></a>
      <div class="cohort-item-2"><h3><span>C4</span></h3></div>
      <a href="/classes-c5" class="cohort-item-2"><h3>C5</h3></a>
      <a href="/classes-c6" class="cohort-item-2"><h3>C6</h3></a>

    </div>
  </div>
</section>

<section id="classes-choice">
   <div class="video-list">
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c4-video-1">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/a8cs5300sl8"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Welcome & Introduction</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-2">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/nRmeeW2UVUQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>History of Crypto Art with Artnome</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-3">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/8p9dei4TkDg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>What are smart contracts and why are they important? <br/>with Isaac</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-4">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/PQMqHRbxiyg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>How does the NFT Market Work? <br/>with Fanny Lakoubay</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-5">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/9ANwErVUd7g"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Ecosystems and Defi <br/>with Jason Dukes</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-6">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/w_95Q04gLcw"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Social media strategy in the NFT space <br/>with Esmay Luck-Hille</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-7">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/L07DjLkqQG8"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>An introduction to DADA art<br/> with Ilan Katin</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-8">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/uVOJGJDfw7Q"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>DAOs and art-related organizations in a decentralized<br/> ecosystem with Miki (ArtSect)</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-9">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ZI5IAkBVKGA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Lucas Aguirre</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-10">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/85NdheA-KFE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Gallery Structures with India Price</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-11">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/zYB3SavsE-c"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Curation of Crypto Art <br/>with Stina Gustafsson</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-12">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/fyU-BKqxfH4"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Introduction to AR and VR in the NFT <br/>ecosystem with Sam AB</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-13">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/1J85YHKuA2I"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Creative Coding Intro with Sableraph</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-14">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/OtOWQV6mSEE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Intro to AI with Ivona Tau</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-15">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/vYdaPh5ePAw"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Sasha Stiles</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-16">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/wlDCVzzOsa8"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>What are DAOs</h2>
    </div>


    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-17">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/esLudi-ZDIE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with ROBNESS</h2>
    </div>
    
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-18">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ch6dOy0qLuU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Architecture / Introduction to the Metaverse<br/> pt I</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-19">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/nRmeeW2UVUQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Intro to Arium Spaces</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-20">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/xPJPl5HG7Wk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Iskra Velitchkova <br/>(pointline __)</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-21">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ldKgrz3IU-A"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Creative Coding Advanced</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-22">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/tXlISz1rLwU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art Critique#1</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-23">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/KUfTjdC27E0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Safety and Security for artists <br/>in the NFT space</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-24">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/DY3KtQQSjnk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Leander Herzog</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-25">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/sMA_f0yqMkg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Architecture Introduction to the Metaverse<br/> pt II</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-26">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/oqL6CKijClc"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Strategies for setting up your marketplace</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-27">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/HQp7F73Ikn0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Installing a hardware wallet and securing your assets</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c4-video-28">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://youtu.be/ySR44OqxVbI"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with ED from KRPDM DAO</h2>
    </div>
    

  </div>
  <div class="video-next">
  <div class="button_slide slide_right" style={{ marginRight: '1em' }}><a href="/classes-c3">← COHORT 3</a></div>
    <div class="button_slide slide_right" style={{ marginLeft: '1em' }}><a href="/classes-c5">COHORT 5 →</a></div>
  </div>
</section>
    </>)
}
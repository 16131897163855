import React from 'react'
import twitter from '../../img/icons/twitter.svg'
import instagram from '../../img/icons/ig.svg'
import discord from '../../img/icons/discord-26.svg'
import youtube from '../../img/icons/yt.svg'
import medium from '../../img/icons/medium.svg'
import heroImage from '../../img/header/header-img-residency.jpg'
import discordCircle from '../../img/icons/discord-light-circle.svg'
import globeDarkCircle from "../../img/icons/globe-dark-circle.svg"
import globeLightCircle from '../../img/icons/globe-light-circle.svg'
import verticalLogo from '../../img/logo/logo-vertical-short.svg'
import email from '../../img/icons/email.svg'

export const Footer = () => {
    let paths = ['/', '/program', '/partners', '/donate', '/application']

    return (<>
        <div class="social-media" style={paths.includes(window.location.pathname) ? { position: 'fixed' } : { position: 'relative' }}>
            <ul>
                <li>
                    <a href="mailto:residency@verticalcrypto.art" target="_blank"><img src={email} alt="" /> </a>
                </li>
                <li>
                    <a href="https://twitter.com/vcaresidency?s=21&t=C494f3TS72MVrv-QfF6Hrw" target="_blank">
                        <img src={twitter} alt="" />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/vcaresidency/" target="_blank">
                        <img src={instagram} alt="" />
                    </a>
                </li>
                <li>
                    <a href="https://discord.gg/hB8RvKZQxp" target="_blank">
                        <img src={discord} alt="" />
                    </a>
                </li>
                <li>
                <a href="https://www.youtube.com/c/verticalcrypto" target="_blank">
                    <img src={youtube} alt="" />
                    </a>
                </li>
                <li>
                    <a href="https://medium.com/@verticalcryptoart" target="_blank">
                        <img src={medium} alt="" />
                    </a>
                </li>
            </ul>
        </div>
        <section className={`${"footer"} ${paths.includes(window.location.pathname) && "footer-fixed"}`}>

<div class="banner-bottom">
  <div class="track-dark">
    <div class="content">&nbsp; Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" /> Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" />Non-Fungible Thinking <img src={verticalLogo} alt="" /></div>
  </div>
</div>

</section>
    </>)
}
import React from 'react'
import heroImage from '../../img/header/header-img-residency-4.jpg'

export const Program = () => {
    var svgTag = `
    <defs>
        <filter id="disFilter">
            <feTurbulence type="turbulence" baseFrequency="0.01" numOctaves="3" seed="1s" result="turbulence">
                <animate attributeName="baseFrequency" values="0.01;0.005;0.01;" dur="30s" begin="0" repeatCount="indefinite"></animate>
            </feTurbulence>
            <fedisplacementmap in="SourceGraphic" in2="turbulence" scale="30" xChannelSelector="R" yChannelSelector="B" result="displacement"></fedisplacementmap>
        </filter>
    </defs>
    <g id="background">
        <image xlink:href=${heroImage} x="0" y="0" height="100%" width="100%" preserveAspectRatio="xMidYMid slice" />
    </g>
    `

    return (
        <div>
            <div class="hero">

                <div class="header-home">
                    <h1>Residency <span>Program</span></h1>
                    <h2><span>Mission</span></h2>
                    <p>Our mission is to democratise access to knowledge by providing free educational programs dedicated to creators of all kinds in the fields of NFTs, Crypto Art, Web3 and the metaverse.</p>
                </div>
            </div>

            <svg id="svg-fixed" dangerouslySetInnerHTML={
                {__html:
                    svgTag
                }
            } />

        <section class="content-program" >

            <h2>Residency Program</h2>
        
            <div class="program-details">
                <div class="program-category-title">
                    <h3>Duration</h3>
                </div>
                <div class="program-category-description">
                    <p>The residency program duration is of 12 weeks with aprox. 20 artists in each cohort. The curriculum starts with an 8-week learning phase, during which residents attend virtual classes covering a range of metaverse-native topics with thought-leaders of the NFT, web3 and crypto-art ecosystem. <br/><br/>The last 4 weeks are dedicated to the creation of NFT artworks. At the end of the residency, artists have the option to donate an artworks towards the VCA Residency Program which will be auctioned with proceeds going towards supporting further residency programs and emerging artists.  
                    </p>
                </div>
            </div>

            <div class="program-details">
                <div class="program-category-title">
                    <h3>Curriculum of classes</h3>
                </div>
                <div class="program-category-description">
                    <p>Some of the topics  covered in the curriculum of the program are: </p>
                    <ol>
                        <li>History of Crypto Art and NFTs</li>
                        <li>Overview of Crypto Art Market, marketplaces and platforms.</li>
                        <li>Creative Coding and Generative Art Basics.</li>
                        <li>Safety and Security for digital assets.</li>
                        <li>Architecture in the Metaverse.</li>
                        <li>Marketing and Promotion within the NFT ecosystem.</li>
                        <li>Art Curation and story-telling for artists.</li>
                        <li>Introduction to VR and AR practices.</li>
                        <li>Introduction to DAOs (Decentralised Autonomous Organisations). </li>
                    </ol> 
                </div>
            </div>
        
            <div class="program-note">
        
                <p>NOTE: ONCE THE PROGRAM HAS ENDED, COHORT GRADUATES WILL REMAIN IN THE CHATROOM AND CONTINUE TO HAVE ACCESS TO SELECt PROGRAM RESSOURCES.</p>
        
            </div>
        
            <div class="class-next">
            
                <a href="/classes"><div class="button_slide slide_right">→ Access classes</div></a>
            
            </div>
        </section>
    </div>
    )
}
import React, { useEffect } from 'react'
// import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/swiper.scss'
import styles from './styles.module.scss'

SwiperCore.use([Autoplay])

export const Partners = () => {
  // Swiper.use([Autoplay]);
  // useEffect(() => {
  //   var swiper = new Swiper('.carousel-gallery .swiper-container', {
  //     effect: 'slide',
  //     speed: 900,
  //     slidesPerView: 5,
  //     spaceBetween: 20,
  //     simulateTouch: true,
  //     autoplay: {
  //       delay: 2000,
  //       stopOnLastSlide: false,
  //       disableOnInteraction: false
  //     },
  //     /*pagination: {
  //       el: '.carousel-gallery .swiper-pagination',
  //       clickable: true
  //     },*/
  //     breakpoints: {
  //       // when window width is <= 320px
  //       320: {
  //         slidesPerView: 1,
  //         spaceBetween: 5
  //       },
  //       // when window width is <= 480px
  //       425: {
  //         slidesPerView: 2,
  //         spaceBetween: 10
  //       },
  //       // when window width is <= 640px
  //       768: {
  //         slidesPerView: 3,
  //         spaceBetween: 20
  //       }
  //     }
  //   }); /*http://idangero.us/swiper/api/*/
  // }, [])

    return (
        <section id="main-alt-2">

  <div class="container-donate-top">
    <div class="header-page">
      <h1>Partners <span>of</span> </h1>
      <h2>the Residency</h2>
    </div>
    <div class="donate-description">
      <p>VericalCrypto Art Residency programs partners with organisations and institutions who cultivate the ethos, and mission of the program. 
        </p>
        <div class="apply-now-partner">
            <div class="button_slide slide_right button_slide_apply_partner"><a href="mailto:residency@verticalcrypto.art?subject=Partner with us">→ Partner with us</a></div>
        </div>
        
    </div>

  </div>

  <Swiper
    className="carousel-gallery"
      effect="slide"
      speed={900}
      slidesPerView={5}
      spaceBetween={20}
      simulateTouch={true}
      autoplay={{ delay: 2000, stopOnLastSlide: false, disableOnInteraction: false }}
      breakpoints={{ 320: { slidesPerView: 3, spaceBetween: 5 }, 425: { slidesPerView: 2, spaceBetween: 10 }, 768: { slidesPerView: 3, spaceBetween: 20 }, 1024: { slidesPerView: 4, spaceBetween: 20 }, 1400: { slidesPerView: 5, spaceBetween: 20 } }}
  >
    <SwiperSlide className={styles.swiper__slide}>
      <a href="https://tezos.foundation/" target="_blank">
        <div class="image" style={{ backgroundImage: 'url(https://verticalcrypto.mypinata.cloud/ipfs/QmQwBAK1ni46FwMkWk1s79EKWNFjyNvkSxZhSshVh13S8Z/Partner-2.png)' }} />
        <div class="content-overlay">
          <h1>Tezos Foundation</h1>
          <p>Tezos is an open-source blockchain protocol for assets and applications backed by a global community of validators, researchers, and builders. The Tezos Foundation stands as part of the community in support of the Tezos protocol and ecosystem.</p>
        </div>
      </a>
    </SwiperSlide>
   
    <SwiperSlide className={styles.swiper__slide}>
    <a href="https://wearemuseums.com/" target="_blank">
                  <div class="image" style={{ backgroundImage: 'url(https://verticalcrypto.mypinata.cloud/ipfs/QmQwBAK1ni46FwMkWk1s79EKWNFjyNvkSxZhSshVh13S8Z/Partner-4.png)' }}>
                  </div>
                  <div class="content-overlay">
                    <h1>We Are Museums</h1>
                    <p>We Are Museums are a global museum community of changemakers spearheading innovation and developing social purpose projects.  
                    
                    </p>
                  
                  </div>
              </a>
    </SwiperSlide>
    <SwiperSlide className={styles.swiper__slide}>
    <a href="https://arium.xyz/" target="_blank">
                  <div class="image" style={{ backgroundImage: 'url(https://verticalcrypto.mypinata.cloud/ipfs/QmQwBAK1ni46FwMkWk1s79EKWNFjyNvkSxZhSshVh13S8Z/Partner-5.png)' }} >
                  </div>
                  <div class="content-overlay">
                    <h1>Arium</h1>
                    <p>Arium Spaces is a metaverse experience to create engaging virtual exhibitions, bring story and context to your artwork and connect with your community in a shared social experience. 
                    
                    </p>
                  
                  </div>
              </a>
    </SwiperSlide>
    <SwiperSlide className={styles.swiper__slide}>
    <a href="https://www.clubnft.com/" target="_blank">
                  <div class="image" style={{ backgroundImage: 'url(https://verticalcrypto.mypinata.cloud/ipfs/QmQwBAK1ni46FwMkWk1s79EKWNFjyNvkSxZhSshVh13S8Z/Partner-6.png)' }}>
                  </div>
                  <div class="content-overlay">
                    <h1>Club NFT</h1>
                    <p>ClubNFT, with founder and CEO Jason Bailey (Artnome) are building the next generation of tools to discover, protect, and share NFTs. 
                    
                    </p>
                  
                  </div>
              </a>
    </SwiperSlide>
    <SwiperSlide className={styles.swiper__slide}>
    <a href="https://www.lumenprize.com/" target="_blank">
                  <div class="image" style={{ backgroundImage: 'url(https://verticalcrypto.mypinata.cloud/ipfs/QmU32SZ57687bzQPuvtvfczWk3TCUnwjVJw5Laq1h9mtad)' }}>
                  </div>
                  <div class="content-overlay">
                    <h1>The Lumen Prize</h1>
                    <p>The Lumen Prize celebrates the very best art created with technology through a global competition. Since it's launch in 2012, it has awarded more than US$100,000 in prize money and created opportunities worldwide for the artists who have been longlisted, shortlisted or won Lumen Awards.
                    
                    </p>
                  
                  </div>
              </a>
    </SwiperSlide>
  </Swiper>
  </section>
    )
}
import React from 'react'
import $ from 'jquery'

export const Artists = () => {
    const filter = (e) => {
        var category = e.target.innerText
  
        $('.work-list li').removeClass('active');
        $(e.target).toggleClass('active');
      
        if(category == 'ALL'){
          $(".work-unit").show(0);
        }else{
          $('.work-unit').hide();
          $('.work-unit').filter('.'+category).show();
        }
    }

    return (
        <section id="main-alt-artists">
  <div class="title-cohort"> <h1>COHORT SELECTION</h1></div>
  <ul class='work-list'>
    <li class='active' onClick={(e) => filter(e)}>ALL</li>
    <li onClick={(e) => filter(e)}>C1</li>
    <li onClick={(e) => filter(e)}>C2</li>
    <li onClick={(e) => filter(e)}>C3</li>
    <li onClick={(e) => filter(e)}>C4</li>
    <li onClick={(e) => filter(e)}>C5</li>
    <li onClick={(e) => filter(e)}>C6</li>
  </ul>
  <div class='work-content'>
   <a href="https://www.twitter.com/cagritaskin" target="_blank"><div class='work-unit C1 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-1.png" alt="" />
      <div class="artist-overlay">
        <h1>Çağrı Taşkın </h1>
        <p>Çağrı Taşkın is an artist and architect based in Istanbul, whose interest lies at the intersection of art, science and technology. </p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/NedtzoArt" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-2.png" alt="" />
      <div class="artist-overlay">
        <h1>Nedtzo</h1>
        <p>Nedtzo is a 3d artist and architect from Iran who makes concept art with futuristic architecture, exploring solarpunk and cyberpunk aesthetics and philosophy.</p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/encapsuled_ " target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Encapsuled.png" alt=""/>
      <div class="artist-overlay">
        <h1>encapsuled</h1>
        <p>encapsuled is a poet and writer from Italy that explores the outer and inner world and capture pieces of them in his works. He likes to play with words and involve collectors in his creative process.</p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/MABLABart" target="_blank"><div class='work-unit C1 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmeKJVVsmuBqRBjPrghTGYSW4zDR9DWhYLcYRo3ZAJXG2C/MABLAB.png" alt="" />
      <div class="artist-overlay">
        <h1>MABLAB</h1>
        <p>MABLAB is an artistic duo formed by two sisters with professional background in architecture, arts and photography.  </p>
      </div>
    </div></a>
    <a href="https://twitter.com/vitalytsarenkov" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Vitaly.png" alt="" />
      <div class="artist-overlay">
        <h1>Vitaly Tsarenkov/SY</h1>
        <p>Vitaly "SY" Tsarenkov is a visual artist from Russia working with digital and analog media. He creates paintings, murals, sculptures, animations, and interactive NFTs in a unified personal style.</p>
      </div>
    </div></a> 
    <a href="https://twitter.com/pgervois" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Pierre.png" alt="" />
      <div class="artist-overlay">
        <h1>Pierre Gervois</h1>
        <p>Pierre Gervois is a crypto artist documenting the society as a silent witness, without any emotion or stated point of view.</p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/atimtay" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-4.png" alt="" />
     
      <div class="artist-overlay">
        <h1>Murat Atimtay</h1>
        <p>Murat is a muldisciplinary artist from Turkey, who explores technology and art using various mediums. </p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/occult3d" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/05-Ikaro_Calvalcante_Occulted.png" alt="" />
      <div class="artist-overlay">
        <h1>Ikaro Cavalcante</h1>
        <p>Ikaro Cavalcante is a non-binary person and a transdisciplinary artist from Brazil, working specially with 3D and sounds that reconstruct common spaces with their affective memory, be they virtual or physical.</p>
      </div>
    </div></a> 
    <a href="https://twitter.com/flpgfx" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Felipe.png" alt="" />
      <div class="artist-overlay">
        <h1>Felipe Filgueiras</h1>
        <p>Felipe Filgueiras is a human visual artist from Brasil,  working its interspace with the alterning results of blurred lines between digital and physical; artificial and biological reality. </p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/walt_artist" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-5.png" alt="" />
    
      <div class="artist-overlay">
        <h1>Walt Reunamo</h1>
        <p>Walt is artist from Finland who mixes traditional, digital and photography art together. </p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/gabriel_koi" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/03-GabrielKoi.png" alt="" />
      <div class="artist-overlay">
        <h1> Gabriel / koi</h1>
        <p>Gabriel koi is a digital artist from Brazil, exploring 3D and generative art to materialize dreams and oniric textures that appear to their inner-self connecting with the multiplicities realities around the world.</p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/abalestrero" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-6.png" alt="" />
    
      <div class="artist-overlay">
        <h1>Andrea Balestrero</h1>
        <p>Andrea is an italian architect working on the border of various disciplines with a focus on the relationship between people objects and space. </p>
      </div>
    </div></a>
    <a href="https://www.twitter.com/bywillak" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-7.png" alt="" />
    
      <div class="artist-overlay">
        <h1>Will A.K</h1>
        <p>Indonesian self-taught artist who is inspired by superflat art, fiction and the real life. </p>
      </div>

    </div></a>
    <a href="https://twitter.com/natalielarrode" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Natalie.png" alt="" />
      <div class="artist-overlay">
        <h1>Natalie Larrodé</h1>
        <p>Larrodé is a figurative and neo-figurative painter from Mexico City. Passionate about oil painting, drawing, and digital art, her work evolves using all the resources at hand and building bridges between different styles and processes.</p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/tuukzs" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-8.png" alt="" />
    
      <div class="artist-overlay">
        <h1>tù.úk'z</h1>
        <p>Brazilian self taught digital artist working with digital experimentation since 2012.</p>
      </div>
    
    </div></a>
    <a href="https://www.twitter.com/jvlive_H" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-9.png" alt="" />
    
      <div class="artist-overlay">
        <h1>warrragwag</h1>
        <p>Springboarding from his background in architecture and abstract drawing, warrragwag speculates on how intermingling of disciplines might look like in the metaverse.</p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/latent_entropy" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Latent.png" alt="" />
      <div class="artist-overlay">
        <h1>latent_entropy</h1>
        <p>latent_entropy is a San Francisco based artist using machine learning to construct surreal digital art. </p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/in_cloudlands" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-10.png" alt="" />
    
      <div class="artist-overlay">
        <h1>Rose Jackson</h1>
        <p>Rose is a textile artist and photographer, working with wool felt, animation and GAN.</p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/by_beba" target="_blank"><div class='work-unit C2 fill'>
      
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/PopArt.png" alt="" />
      <div class="artist-overlay">
        <h1>Pop Art By Beba</h1>
        <p>Beba is a Colombian artist based in Canada with a lifelong passion for pop art. Her light-hearted style focuses on Vintage Romance Comics with a modern twist. </p>
      </div>
    </div></a> 
    <a href="https://twitter.com/stefan_contiero" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Stefano.png" alt="" />
      <div class="artist-overlay">
        <h1>Stefano Contiero</h1>
        <p>Stefano Contiero is a self-taught artist, half Italian and half Dominican. With an early interest in technology and design, after building a career in tech, his passions meet in Generative Art, the perfect union between code and visual arts. </p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/lindadouniar" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-11.png" alt="" />
      
      <div class="artist-overlay">
        <h1>Linda Dounia</h1>
        <p>Multi-disciplinary artist, designer, and writer from Senegal. My work interrogates the effects of patriarchal, modernist, and capitalistic power structures on black bodies, minds, and imagination.</p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/garrison4tress" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/04-GarrisonFortress.png" alt="" />
      <div class="artist-overlay">
        <h1>Garrison Fortress</h1>
        <p>Garrison is an Artist, Musician and Filmmaker. His work is cartoonish, self-taught, informed by shows he watches with his kids; and bizarre because with friends, he expands his mind. </p>
      </div>
    </div></a> 
    <a href="https://twitter.com/FahmiMursyid" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Fahmi.png" alt="" />
      <div class="artist-overlay">
        <h1>Fahmi Mursyid</h1>
        <p>Fahmi Mursyid is an experimental artist and multimedia designer based in Indonesia.</p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/octavioirving2" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-12.png" alt="" />
      
      <div class="artist-overlay">
        <h1>Octavio Irving </h1>
        <p>Octavio Irving is a visual artist from Cuba, coming from a traditional backgroun in the arts for the last 20years.</p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/Christian_NFT" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Christian.png" alt="" />
      <div class="artist-overlay">
        <h1>Christian Hernandez</h1>
        <p>Christian Hernandez is a self-taught Illustrator and Abstract artist from the Southside of Atlanta, Georgia. His work illustrates a variety of cultural references that inspired him, while maintaining an abstract viewpoint.</p>
      </div>
    </div></a> 
    <a href="https://www.twitter.com/KalenIwamoto" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-14.png" alt="" />
    
      <div class="artist-overlay">
        <h1>Kalen Iwamoto</h1>
        <p>Kalen is a crypto writer, who creates crypto-native conceptual writing NFTs. </p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/Richi0118" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/MrRichi.png" alt="" />
      <div class="artist-overlay">
        <h1>Mr Richi</h1>
        <p>Mr Richi is a self-taught digital artist from Belgrade with early beginnings in graffiti and street art, which made heavy impact on his art as it is today.</p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/BoryaXYZ" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/02-Borya.png" alt="" />
      <div class="artist-overlay">
        <h1>Borya Shapshalova</h1>
        <p>Borya Shapshalova is a Bulgarian Inter-disciplinary artist. She integrates modalities as a medium for consciousness research and boundary-busting environment with unexpected outcomes. </p>
      </div>
    </div></a> 
    <a href="https://twitter.com/micalnoelson" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/06-NOELSON.png" alt="" />
      
      <div class="artist-overlay">
        <h1>Mical Noelson</h1>
        <p>Mical Noelson, raised in London, of English and Caribbean decent, with a studying background in art, he spent more than 7 years recording and performing music. </p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/hypereikon" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/hyperikon.png" alt="" />
      <div class="artist-overlay">
        <h1>Hypereikon</h1>
        <p>Hypereikon is an artistic duo of experimental creation based in Valdivia, Chile conformed by María Constanza Lobos and Sebastian Rojas.</p>
      </div>
    
    </div></a>
    <a href="https://www.twitter.com/egarsreal" target="_blank"><div class='work-unit C1 fill'><img src="https://verticalcrypto.mypinata.cloud/ipfs/Qme66C1qJ4NajWfJgUGDWqiKiBH1faq6CMqi6xXyaDBu1r/artist-13.png" alt="" />
    
      <div class="artist-overlay">
        <h1>Negar K</h1>
        <p>Negar K is a 24 years old photographer/storyteller based in Iran.</p>
      </div>
    
    </div></a>
    <a href="https://twitter.com/zareenfava" target="_blank"><div class='work-unit C2 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmSs5KiJkMeZNy1Xy4GgMAJuioQt1qJYM9m2z47PTr6CbS/Zareen.png" alt="" />
      <div class="artist-overlay">
        <h1>Zareen Fava</h1>
        <p>Zareen is a Kashmir-American maker, living in Spain. Using recycled materials and stop-motion, she puzzles-out new ideas from Kashmir culture and history, and interprets them through the NFT medium, in search of new truths.</p>
      </div>
    </div></a>
    <a href="https://twitter.com/krivi_mp4" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmZ7WbCcMQAEfGjFusvMXgT61ShZkCKoRozirN66FGKYg7/Ivelina%20Ivanova.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Ivelina Ivanova</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/neymrqz" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/santiago%20marquez.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Santiago Marquez</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/ruralidyll" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/DylanWhite_ruralidyll.jpeg" alt="" />
      <div class="artist-overlay">
        <h1>Dylan White</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/cgcortex" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmbY3gFFHSsbKsb5sLVw9xaxzsE1PmUCHMt7ccNLF1h1oK" alt="" />
      <div class="artist-overlay">
        <h1>CGCortex</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/luisricartoons" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/LuisRicardo.jpeg" alt="" />
      <div class="artist-overlay">
        <h1>Luis Ricardo</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/CaballeroAnaMa" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Ana%20Mari%CC%81a%20Caballero.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Ana Maria Caballero</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/unpetitsapin" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmPsHGKTTuNAwPiLwwgagm3uTnDQiRUJQkk2FFGwa7NzH1?filename=profile-pic-web.jpgj" alt="" />
      <div class="artist-overlay">
        <h1>petitsapin</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/earthsample" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/earthsample.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Earthsample</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/JeanSaucisse6" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Jean.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Jean</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/chabeli_farro" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Chabeli%20Farro_.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Chabeli Farro</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/ismahelios" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/ismahelio.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Ismahelio</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/ZeroAlpha_NZ" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/ZeroAlpha.png" alt="" />
      <div class="artist-overlay">
        <h1>Zero Alpha</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/zala_faz_zalas" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Ana%20Zala.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Ana Zala</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/claudslr" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
      <div class="artist-overlay">
        <h1>Claudia Pawlak</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/pat_infinity" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Patricia.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Patricia Infinity</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/InessRychlik" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Iness%20Rychlik.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Iness Rychlik</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/annaluciacodes" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Anna%20Lucia.PNG" alt="" />
      <div class="artist-overlay">
        <h1>Anna Lucia</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/HughesMichi" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Michael%20Hughes.png" alt="" />
      <div class="artist-overlay">
        <h1>Michael Hughes</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/cdammr" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/Chris%20Dammeyer.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Chris Dammeyer</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/anubis_3100" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWVAYBGnxHz1os46gZ48mkDeDYgA14cHiXU38kiEDX2Uc/anubis.png" alt="" />
      <div class="artist-overlay">
        <h1>Anubis3100</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/dddpxc" target="_blank"><div class='work-unit C3 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
      <div class="artist-overlay">
        <h1>Denu</h1>
      </div>
    </div></a>

    

    <a href="https://www.twitter.com/abieyuwaart" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/1-Abieyuwa.png" alt="" />
      <div class="artist-overlay">
        <h1>Abieyuwa</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/ik80" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/10-IuriKothe.png" alt="" />
      <div class="artist-overlay">
        <h1>Iuri Kothe</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/joelno14" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/11-JoelOmolo.png" alt="" />
      <div class="artist-overlay">
        <h1>Joel Meshak</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/j0nasbo" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/12-JonasBo.png" alt="" />
      <div class="artist-overlay">
        <h1>Jonas Bohatsch</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/layamathikshara" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/13-LayaMathikshara.png" alt="" />
      <div class="artist-overlay">
        <h1>Laya Mathikshara</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/LaylaPizarro" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/14-LaylaPizarro.png" alt="" />
      <div class="artist-overlay">
        <h1>Layla Pizarro</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/nathanielstern" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/15-NathanielStern.png" alt="" />
      <div class="artist-overlay">
        <h1>Nathaniel Stern</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/x_rVb_x" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/16-ReVerseButcher.png" alt="" />
      <div class="artist-overlay">
        <h1>ReVerse Butcher</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/touchsoundart" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/17-touchsoundart.png" alt="" />
      <div class="artist-overlay">
        <h1>Touchsoundart</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/vicdoval" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/18-VictorDoval.png" alt="" />
      <div class="artist-overlay">
        <h1>Victor Doval</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/WynLynTan" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/19-Wyn-LynTan.png" alt="" />
      <div class="artist-overlay">
        <h1>Wyn-Lyn Tan</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/bodh_io" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/2-bodh.png" alt="" />
      <div class="artist-overlay">
        <h1>bodh.io</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/juls_gabs" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/20-JulsGabs.png" alt="" />
      <div class="artist-overlay">
        <h1>Juls Gabs</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/brutalisti" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/3-BRUTALISTI.png" alt="" />
      <div class="artist-overlay">
        <h1>Brutalisti </h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/eko3316" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/4-Eko33.png" alt="" />
      <div class="artist-overlay">
        <h1>Eko33 / Jean-Jacques Duclaux</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/ellabarnesart" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/5-EllaBarnes.png" alt="" />
      <div class="artist-overlay">
        <h1>Ella Barnes</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/em_pasin" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/6-EmanuelePasin.png" alt="" />
      <div class="artist-overlay">
        <h1>Emanuele Pasin</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/wldr_artLab" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/7-FlorinaLozinca.png" alt="" />
      <div class="artist-overlay">
        <h1>Florina Lozinca (Wilder ArtLab) </h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/FrancoBellavit1" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/8-FrancoBellavita.png" alt="" />
      <div class="artist-overlay">
        <h1>Franco Bellavita</h1>
      </div>
    </div></a>
    <a href="https://www.twitter.com/ignis____" target="_blank"><div class='work-unit C4 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmRM6ECJe7HKcUNhSXvyKnA8kDqKvDg6WNPXoy2PrNRutu/9-IgnacioSantander.png" alt="" />
      <div class="artist-overlay">
        <h1>Ignacio Santander - Ignis</h1>
      </div>
    </div></a>
   

    <a href="https://twitter.com/a_javaloyas" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/alejandro-3%20-%20Alejandro%20Javaloyas.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Alejandro Javaloyas</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/AndruSenn" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/bh%20-%20Andr%C3%A9s%20Senn.png" alt="" />
      <div class="artist-overlay">
        <h1>Andrés Senn</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/bassil_taleb" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/BT%20-%20PFP%20-%20BASSIL%20TALEB.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Bassil Taleb</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/DrawingMoving" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/avatar-4-light%20-%20Cezar%20Mocan%20-%20moving.drawings.png" alt="" />
      <div class="artist-overlay">
        <h1>moving.drawing</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/christianbok" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/Christian%20Bok%20-%20Drawing%20-%20Christian%20Bok.png" alt="" />
      <div class="artist-overlay">
        <h1>Christian Bök</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/cpadron_art" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/foto%20de%20artista%20-%20Claudia%20Padr%C3%B3n%20G%C3%B3mez.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Claudia Padrón</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/conniebakshi" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/ConnieBakshi_PFP%20-%20Connie%20Bakshi.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Connie Bakshi</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/Rrose_Selavy_11" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/Danielle%20King%20-%20Danielle%20King.png" alt="" />
      <div class="artist-overlay">
        <h1>Danielle King</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/davidhenrynobo1" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/David%20Nobody.jpeg" alt="" />
      <div class="artist-overlay">
        <h1>David Nobody</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/distcollective" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/DistCollective%20-%20bilge%20g%C3%BCnay.jpg" alt="" />
      <div class="artist-overlay">
        <h1>DistCollective</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/distcollective" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/DistCollective%20-%20%C4%B1lg%C4%B1n%20i%C3%A7%C3%B6z%C3%BC.jpg" alt="" />
      <div class="artist-overlay">
        <h1>DistCollective</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/EvaEllerArt" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/ANGEL%20OF%20KNOWLEDGE%20copy%20-%20Eva%20Eller.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Eva Eller</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/HeliodoroSantos" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/1.helio2%20-%20heliodoro%20santos.png" alt="" />
      <div class="artist-overlay">
        <h1>Helio Santos</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/IrinAngles" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
      <div class="artist-overlay">
        <h1>Irina Angles</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/gangster__B" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/kashushu.jpeg" alt="" />
      <div class="artist-overlay">
        <h1>Kashushu</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/ioan1x" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/IMG_2915%20-%20Luca%20Ionescu.JPG" alt="" />
      <div class="artist-overlay">
        <h1>Luca Ionescu</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/lucianaguerraok" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/Luciana%20Guerra.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Luciana Guerra</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/MarthaLSkinner" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/martha%20skinner.jpeg" alt="" />
      <div class="artist-overlay">
        <h1>Martha Skinner</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/mayorlawee" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/Lawal%20Mayowa.jpeg" alt="" />
      <div class="artist-overlay">
        <h1>Mayowa Lawal</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/madebyoona" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
      <div class="artist-overlay">
        <h1>OONA</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/taiimazz" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/IMG_1598%202%20-%20Taimaz%20Ashtari.jpg" alt="" />
      <div class="artist-overlay">
        <h1>taimazz</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/tomlauerman" target="_blank"><div class='work-unit C5 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQAXNndS2hJSbBKqjex7dHtbRyXggPgmWHBL8dHsQM7HX/tomlauerman%20-%20Tom%20Lauerman.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Tom Lauerman</h1>
      </div>
    </div></a>

    <a href="https://twitter.com/aethersovereign" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWRgUs3F4ZB6ZERB2sL52Bz2MxpgJShpU5Z3C9Hbr3Kep" alt="" />
      <div class="artist-overlay">
        <h1>æther</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/afroscope_" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmeRQzWZw4RoHEo1fd62soCvnwYX9dNALa8sp5u9o8t74e" alt="" />
      <div class="artist-overlay">
        <h1>Afroscope</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/d4nd4_" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNmeSb5Ys4MC6guD4iXPhsVj2RgaZ6QJbJ8nJBws4E7bS" alt="" />
      <div class="artist-overlay">
        <h1>Amanda Homem</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/altgnon" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmZ3nh4aSfMhWLDwdZNjPWjxPAW7hFcUQGGmCf3t4vSF4F" alt="" />
      <div class="artist-overlay">
        <h1>altgnon</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/andrealitmus" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX21RqWDYRKib3NPwb8qz1dtq91XQe2JVxa8ERUmVjayr" alt="" />
      <div class="artist-overlay">
        <h1>andrealitmus</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/autoeclectus" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmUk6uoiSuifiQhotPTGrRAQxQsXvi2Azc8dihxrdFur1m" alt="" />
      <div class="artist-overlay">
        <h1>AutoEclectus</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/carodebartolo" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmTFqFnpkjMq86SB5baj5VJpTRSJ9A4HRp8Kby2e7kAx5e" alt="" />
      <div class="artist-overlay">
        <h1>Carolina de Bartolo</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/xCROSSLUCID" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmcPWQNu679DBXkP5CTfYNcXV56wZiW5UcRz6qwJraYaYp?filename=xLUCID_Online%2520-%2520Cassius%2520Libertine.jpg" alt="" />
      <div class="artist-overlay">
        <h1>CROSSLUCID</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/danaAfanego" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmf43j5anFvFTKVVKTh8pYHTwSyRTJatNXUTC8Wr5C2SVo" alt="" />
      <div class="artist-overlay">
        <h1>DanaFanego</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/davegreber" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNmwNUv3TVMGBWxGnMDdwz518Gnwze1bCJ8dSrrVCqsCh" alt="" />
      <div class="artist-overlay">
        <h1>Dave Greber</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/emilyaweil" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmVbbof8YYozAjUvb1dLQN14un9YnbXkd6gidnYaJYVuFj?filename=Twitter_2023_EmilyWeil_ProfilePict_500x500_1%2520-%2520Emily%2520Weil.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Emily</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/aaasonipse" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmW5bYEmZeSRVz9A7PeTanUyRG8KhrXPvvr5kAnsRmuuNB?filename=glitch%2520pfp%25201_smol%2520-%2520Matias%2520Espinosa.png" alt="" />
      <div class="artist-overlay">
        <h1>espinosa</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/estelle_flores_" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmUxwz7w6EvC4ga4CTuDdzj2wMhWnPi2tJ5GV1syqQejHt/artifact%20-%20Selva%20Press.png" alt="" />
      <div class="artist-overlay">
        <h1>Estelle Flores</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/_Evelyn_O_" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmUxwz7w6EvC4ga4CTuDdzj2wMhWnPi2tJ5GV1syqQejHt/evelyn%20-%20evelyn%20o.jpeg" alt="" />
      <div class="artist-overlay">
        <h1>evelyn</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/FilippoFiltro" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmfStcmWVwMU63rBVq39GBgSQr27viWfi4rFkZRoRYKwcJ" alt="" />
      <div class="artist-overlay">
        <h1>Filippo</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/icasis" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmWBaW7XseBJXrhgvnt4ZdATp2GMHmWaAaW6RdAcyG2VP2" alt="" />
      <div class="artist-overlay">
        <h1>icasis</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/MCHX17" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmUV9WmYHS146qn14pMBqtvCpCEiDvB5tDvMV15P2qhFoV" alt="" />
      <div class="artist-overlay">
        <h1>mchx</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/_noumenal" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmcHYtwv7p8hGzDmweuyDC2x6j9WFzW68beb5hv9984CBG" alt="" />
      <div class="artist-overlay">
        <h1>noumenal</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/ParinHeidari" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmUxwz7w6EvC4ga4CTuDdzj2wMhWnPi2tJ5GV1syqQejHt/Parin%20Heidari%20pfp%20-%20Parin%20Heidari.jpg" alt="" />
      <div class="artist-overlay">
        <h1>Parin</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/PawelDudko" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmcCeshmJ8RrVJhTsbkooT8zqQ8Kv75vKfqnuQB2UJ1NtF" alt="" />
      <div class="artist-overlay">
        <h1>Pawel Dudko</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/ponio_ponio" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmR9f7imBNKSqoXXBY8aA6Twd5CAV13g3TE2PFpJ3bWvL2" alt="" />
      <div class="artist-overlay">
        <h1>Ponio</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/riniifish" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmPFj9BqyKZj29y3JcpGaFjuxGcwJLcSRFqCkXE18zx2mH" alt="" />
      <div class="artist-overlay">
        <h1>Riniifish</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/Sko_hr" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmQuUSVsKaTpAv8TnBymg13et1tEpRb4tT61UBPJDyWDZX" alt="" />
      <div class="artist-overlay">
        <h1>Saeko Ehara</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/Helifilms" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmaDWRMwUsyFFV6F2fgeg7eTHPVcR1ybhSSfHhBWkKtbXb" alt="" />
      <div class="artist-overlay">
        <h1>SUNGRAZERS</h1>
      </div>
    </div></a>
    <a href="https://twitter.com/Valeriana_infus" target="_blank"><div class='work-unit C6 fill'>
      <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmUdVNhDLrxfeb9qCHMbQxY3fjwapHVyM3sJeLFDvxKi7s" alt="" />
      <div class="artist-overlay">
        <h1>Valeriana</h1>
      </div>
    </div></a>

    
  </div>
        </section>
    )
}
import React, { useEffect, useState } from 'react'
import Tilt from 'react-parallax-tilt'
import $ from 'jquery'

const checkPass = (pass) => {
  if (pass === 'vertical21-22resident') {
    return true
  } else {
    return false
  }
}

export const ClassesC5 = () => {
  const [viewing, setViewing] = useState(false);
  const [context, setContext] = useState(null);
  const [content, setContent] = useState(null);

  const handleViewClass = (event) => {
    let context = $(event.currentTarget).attr('data-lightbox-type');
    let content = $(event.currentTarget).attr('data-lightbox-content');

    setContext(context);
    setContent(content);
    setViewing(true);
  }

  useEffect(() => {
    if (viewing === true) {
      handlePassClass()
    }
  }, [viewing])

  const handlePassClass = (event) => {
    console.log(viewing)

    console.log('clicked');
    $('.lightbox').fadeToggle('fast');
  
    // console.log(event);
    if (context == 'video') {
      $('.lightbox-column').append(`
          <div class="lightbox-video">
          <iframe src="${content}" frameborder="0" allowfullscreen> </iframe>
          </div>
      `);
    } else if (context == 'image') {
      $('.lightbox-column').append(`
          <img src="${content}" class="img-" frameborder="0" allowfullscreen>
      `);
    }
  }

    return (<>

      {viewing && (
            <div class="lightbox" style={{ zIndex: 9999 }}>
            <a href="#lightbox" class="lightbox-close lightbox-toggle" onClick={() => setViewing(false)}>X</a>
            <div class="lightbox-container">
              <div class="row">
                <div class="col-sm-12 lightbox-column">
                  
                </div>
              </div>
            </div>
          </div>
      )}

        <section id="main-alt-classes">

  <div class="container-classes-top">
    <div class="header-page w-50">
      <h1>Residency </h1>
      <h2>Classes</h2>
    </div>
    <div class="cohort-title">

      <a href="/classes" class="cohort-item-1"><h3>C1</h3></a>
      <a href="/classes-c2" class="cohort-item-2"><h3>C2</h3></a>
      <a href="/classes-c3" class="cohort-item-2"><h3>C3</h3></a>
      <a href="/classes-c4" class="cohort-item-2"><h3>C4</h3></a>
      <div class="cohort-item-2"><h3><span>C5</span></h3></div>
      <a href="/classes-c6" class="cohort-item-2"><h3>C6</h3></a>
    </div>
  </div>
</section>

<section id="classes-choice">
   <div class="video-list">
   <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c5-video-1">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/u52Lm7PdKQI"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Welcome & Introduction</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-2">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/mAUNS3oclKU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>History of Crypto Art</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-3">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/6r5NDkszSqE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>What are smart contracts and why are they important?</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-4">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/IDWlhclVy5s"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with SkyGolpe & Jesse Draxler</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-5">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/XTpHP5dLt-E"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Aesthetics and Encrypted Histories <br/>with Alex Estorick</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-6">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/W7QRa1zJMiE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Social media strategy in the NFT space<br/> with Patrick Amadon</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-7">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/VJQzpU7-GzI"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>How does the NFT Market Work<br/> with Fanny Lakoubay</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-8">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/V_pbrx9Ifxk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>An introduction to DADA art</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-9">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/zKeK_pkePYg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art Critique #1 <br/>with Harry Martin</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-10">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/fSXu0OYOR-w"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>DAOs and art related organizations in a <br/>decentralized ecosystem with Miki Elson (ArtSect)</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-11">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/CIIcccvFhds"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Ana Maria Caballero</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-12">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/lPs-FK8PmQY"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Gallery Structures <br />with India Prince</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-13">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/egRHQP--Z-U"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Museums, Art History and Digital Art in the context of NFTs <br />with Bernadine Brocker</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-14">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/WWDXvrk-VBw"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art Critique #2 <br />with Harry Martin</h2>
    </div>


    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-15">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ESAh4_iX1BE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Operator</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-16">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/eq-UFJz594w"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Residency check-in with Micol</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-17">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ZWLLkZ5n69o"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Installing a hardware wallet and securing your assets</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-18">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/a3yMC6PE-Fs"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Intro to AI</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-19">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/P5DWb_bvO_I"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Art of NFT Construction</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-20">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/xPkkyN0gkxk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Creating Coding (Intro)</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-21">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/XyCHLicYQ-g"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Architecture in the Metaverse</h2>
    </div>

     <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-22">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/hOg7S54MDVk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Curation Q&A <br />with Aleksandra Art</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-23">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/R6L7dq9RhPU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Creating Coding Advanced</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-24">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/gWcDEZPDdz4"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art Critique #3</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-25">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/rAtjnM_Htk0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Work on Blender and <br /> how to build a Metaverse in Atrium Spaces</h2>
    </div>
  
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-26">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/22ubPcBGbzo"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA <br /> with Jonas Lund</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-27">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/kiQeeXZk7ng"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Curating Generative Art</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-28">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/Tr7ETK-Jerg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art Critique #4</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-29">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/xLa4i5sX3CI"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA <br /> with AL Crego</h2>
    </div>

     <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-30">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/3ZGP2sIibOQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Residency Update - Auction & Exhibition <br /> with Micol</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-31">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/_GQWMic4abk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Ecosystems and Defi</h2>
    </div>

     <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-32">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/6Hpvv9S07hc"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Strategies for setting up your marketplace</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-33">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/WsTOW9Idd5g"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>What are DAOs</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c5-video-34">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/c0iLAFSOW1c"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Introduction to AR & VR in the NFT ecosystem</h2>
    </div>
  </div>
  <div class="video-next">
  <div class="button_slide slide_right" style={{ marginRight: '1em' }}><a href="/classes-c4">← COHORT 4</a></div>
    <div class="button_slide slide_right" style={{ marginLeft: '1em' }}><a href="/classes-c6">COHORT 6 →</a></div>
  </div>
</section>
    </>)
}
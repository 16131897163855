import React, { useEffect } from 'react'

import twitter from '../../img/icons/twitter.svg'
import instagram from '../../img/icons/ig.svg'
import discord from '../../img/icons/discord-26.svg'
import youtube from '../../img/icons/yt.svg'
import medium from '../../img/icons/medium.svg'
import heroImage from '../../img/header/header-img-residency.jpg'
import discordCircle from '../../img/icons/discord-light-circle.svg'
import globeDarkCircle from "../../img/icons/globe-dark-circle.svg"
import globeLightCircle from '../../img/icons/globe-light-circle.svg'
import verticalLogo from '../../img/logo/logo-vertical-short.svg'

import './styles.module.scss'

var svgTag = `<defs>
<filter id="disFilter">
  <feTurbulence type="turbulence" baseFrequency="0.01" numOctaves="3" seed="1s" result="turbulence">
    <animate attributeName="baseFrequency" values="0.01;0.005;0.01;" dur="30s" begin="0" repeatCount="indefinite"></animate>
  </feTurbulence>
  <fedisplacementmap in="SourceGraphic" in2="turbulence" scale="30" xChannelSelector="R" yChannelSelector="B" result="displacement"></fedisplacementmap>
</filter>
</defs>
<g id="background">
<image xlink:href=${heroImage} x="0" y="0" height="100%" width="100%" preserveAspectRatio="xMidYMid slice"></image>
</g>`

export const Banner = () => {
  return (
    <div class="banner">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfH1hjXYy2eETOv4GhSwnNehjOSm6-I-kiCzBTM5XJf8xs-3Q/viewform">We are now accepting applications for VCA Residency Program! Click here to apply</a>
    </div>
  )
}

export const Home = () => {
  const [message, setMessage] = React.useState('')

  const resolver = {
    resolve: function resolve(options, callback) {
      // The string to resolve
      const resolveString = options.resolveString || message;
      const combinedOptions = Object.assign({}, options, { resolveString: resolveString });
  
      function getRandomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      };
  
      function randomCharacter(characters) {
        return characters[getRandomInteger(0, characters.length - 1)];
      };
  
      function doRandomiserEffect(options, callback) {
        const characters = options.characters;
        const timeout = options.timeout;
        const partialString = options.partialString;
  
        let iterations = options.iterations;
  
        setTimeout(() => {
          if (iterations >= 0) {
            const nextOptions = Object.assign({}, options, { iterations: iterations - 1 });
  
            // Ensures partialString without the random character as the final state.
            if (iterations === 0) {
              setMessage(partialString)
              // element.textContent = partialString;
            } else {
              // Replaces the last character of partialString with a random character
              setMessage(partialString.substring(0, partialString.length - 1) + randomCharacter(characters))
              // element.textContent = partialString.substring(0, partialString.length - 1) + randomCharacter(characters);
            }
  
            doRandomiserEffect(nextOptions, callback);
          } else if (typeof callback === "function") {
            callback();
          }
        }, options.timeout);
      };
  
      function doResolverEffect(options, callback) {
        const resolveString = options.resolveString;
        const characters = options.characters;
        const offset = options.offset;
        const partialString = resolveString.substring(0, offset);
        const combinedOptions = Object.assign({}, options, { partialString: partialString });
  
        doRandomiserEffect(combinedOptions, () => {
          const nextOptions = Object.assign({}, options, { offset: offset + 1 });
  
          if (offset <= resolveString.length) {
            doResolverEffect(nextOptions, callback);
          } else if (typeof callback === "function") {
            callback();
          }
        });
      };
  
      doResolverEffect(combinedOptions, callback);
    } };
  
  
  /* Text here
   */
  const strings = [
  'VerticalCrypto Art (VCA) is the curatorial house for art & culture on the blockchain, working across art, music and fashion. VCA empowers and elevates culture, through curation, redefining what contemporary culture is."',
  'Our mission is to democratise access to knowledge by providing free educational programs dedicated to creators of all kinds in the fields of NFTs, Crypto Art, Web3 and the metaverse.',
  
  'Explore the website to learn more about our residency program.'];
  
  
  let counter = 0;
  
  const options = {
    // Initial position
    offset: 0,
    // Timeout between each random character
    timeout: 5,
    // Number of random characters to show
    iterations: 6,
    // Random characters to pick from
    characters: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'x', 'y', 'x', '#', '%', '&', '-', '+', '_', '?', '/', '\\', '='],
    // String to resolve
    resolveString: strings[counter],
  }
  
  // Callback function when resolve completes
  function callback() {
    setTimeout(() => {
      counter++;
  
      if (counter >= strings.length) {
        counter = 0;
      }
  
      let nextOptions = Object.assign({}, options, { resolveString: strings[counter] });
      resolver.resolve(nextOptions, callback);
    }, 1000);
  }
  
  useEffect(() => {
    resolver.resolve(options, callback);
  }, [])

    return (
        <div>
            <div class="hero">

        <div class="header-home">            
            <h1>Democratising <span>Access</span></h1>
            <h2>to <span>knowledge</span></h2>
            <p>{message}</p>
        </div>
</div>

<svg id="svg-fixed" dangerouslySetInnerHTML={
  {__html:
    svgTag
  }
} />

<section class="content-home-side" >

    <h2>What we do</h2>
    
    <div class="card card-xs">
        <div class="card__content">
          <h4>Learn more about <span><br/>VerticalCrypto Art</span></h4>
          <p>VerticalCrypto Art (VCA) is the curatorial house for art & culture on the blockchain, working across art, music and fashion. VCA empowers and elevates culture, through curation, redefining what contemporary culture is.</p>
        </div>
        <footer class="card__footer"><a href="https://verticalcrypto.art/" target="_blank"><img src={globeLightCircle}/><span>Visit our website</span></a>
        </footer>
      </div>
      <div class="card card--answer card--spaces">
        <div class="card__content">
          <h4>About<span><br/>our mission</span></h4>
          <p>Our mission is to democratise access to knowledge by providing free educational programs dedicated to creators of all kinds in the fields of NFTs, Crypto Art, Web3 and the metaverse. <br/><br/>
            These programs are committed and devoted to support, uplift, educate and provide the tools and resources to empower creators and artists around the world on metaverse and NFT-native skills. 
            </p>
        </div>
        <footer class="card__footer"><a href="/program"><img src={globeDarkCircle}/><span>Residency program</span>
        </a></footer>
      </div>
      <div class="card card-xs">
        <div class="card__content">
          <h4>Join our Discord <span><br/>community</span></h4>
          <p>Join the VCA discord community to meet like-minded individuals, learn more about the program & be actively involved in our initiatives.</p>
        </div>
        <footer class="card__footer"><a href="https://discord.com/invite/DHgptcrEUm" target="_blank"><img src={discordCircle}/><span>Join us</span></a>
        </footer>
      </div>
    </section>
        </div>
    )
}
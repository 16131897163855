import React, { useEffect, useState } from 'react'
import Tilt from 'react-parallax-tilt'
import $ from 'jquery'

const checkPass = (pass) => {
  if (pass === 'vertical21-22resident') {
    return true
  } else {
    return false
  }
}

export const ClassesC3 = () => {
  const [viewing, setViewing] = useState(false);
  const [context, setContext] = useState(null);
  const [content, setContent] = useState(null);

  const handleViewClass = (event) => {
    let context = $(event.currentTarget).attr('data-lightbox-type');
    let content = $(event.currentTarget).attr('data-lightbox-content');

    setContext(context);
    setContent(content);
    setViewing(true);
  }

  useEffect(() => {
    if (viewing === true) {
      handlePassClass()
    }
  }, [viewing])

  const handlePassClass = (event) => {
    console.log(viewing)

    console.log('clicked');
    $('.lightbox').fadeToggle('fast');
  
    // console.log(event);
    if (context == 'video') {
      $('.lightbox-column').append(`
          <div class="lightbox-video">
          <iframe src="${content}" frameborder="0" allowfullscreen> </iframe>
          </div>
      `);
    } else if (context == 'image') {
      $('.lightbox-column').append(`
          <img src="${content}" class="img-" frameborder="0" allowfullscreen>
      `);
    }
  }

    return (<>

      {viewing && (
            <div class="lightbox" style={{ zIndex: 9999 }}>
            <a href="#lightbox" class="lightbox-close lightbox-toggle" onClick={() => setViewing(false)}>X</a>
            <div class="lightbox-container">
              <div class="row">
                <div class="col-sm-12 lightbox-column">
                  
                </div>
              </div>
            </div>
          </div>
      )}

        <section id="main-alt-classes">

  <div class="container-classes-top">
    <div class="header-page w-50">
      <h1>Residency </h1>
      <h2>Classes</h2>
    </div>
    <div class="cohort-title">
      <a href="/classes" class="cohort-item-1"><h3>C1</h3></a>
      <a href="/classes-c2" class="cohort-item-2"><h3>C2</h3></a>
      <div class="cohort-item-2"><h3><span>C3</span></h3></div>
      <a href="/classes-c4" class="cohort-item-2"><h3>C4</h3></a>
      <a href="/classes-c5" class="cohort-item-2"><h3>C5</h3></a>
      <a href="/classes-c6" class="cohort-item-2"><h3>C6</h3></a>
    </div>
  </div>
</section>

<section id="classes-choice">
   <div class="video-list">
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-1">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/G8-D7foG5po"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Intro to the VerticalCrypto Art Residency-3rd Cohort</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c3-video-1">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/U8FVegD_T3U"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art History with Artnome</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c3-video-2">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/7SOwsYPtdBU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>How does the NFT market work with F. Lakoubay</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-3">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/MtBM0APt8as"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto EcoSystems and DeFi with Jason Dukes</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-4">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/QmaAu8V49Xs"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Sarah Zucker</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-5">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/h8AiDUjfqD0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Being artists in the crypto world & the importance <br/>
      of community-building w/ Linda & Kalen</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-6">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/HCND7Axlcig"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Museums, Art History and Digital Art in the <br />context of NFTs with Bernardine Brocker</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-7">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/tLHPmrXJVyU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Intro to DADA.art</h2>

    </div>

   

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-9">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/yEVhKaZ7wSQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Social Media & Branding in the NFT space w/ Esmay</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-8">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/VyFA7X6PizQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Deep dive into algorithmic <br /> arts & curation w/ Sofia Garcia</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-10">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/uUaqivu7mNk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Safety and Security for artists in the <br /> NFT space w/ Martin Lukas Ostachowski</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-11">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/f1Asl_ca"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA W/Colborn and Shivani <br /> from Museum of Crypto Art</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-12">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/1EMk7jgTcGU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Introduction to AI w/ Ivona Tau</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-13">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/obaoaxCupCI"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Curation of Crypto Art w/Stina Gustaffson</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-14">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/0FCDIuO05OQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA W/ Fabin</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-15">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/FTdleeHGWo8"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Introduction to AR and VR in <br /> the NFT ecosystem w/Sam AB</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-16">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/OK3hu_QPIBk"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Intro to Creative Coding w/ Sable Raph</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-17">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/JgEy6etGJqA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA w/ P1xelfool</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-18">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/qZBJWISGzuY"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA w/ Adrian Crego</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-19">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/bMwOHg8hzck"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Intro to AlterHEN w/ Diane Drubay & Patrick Tresset</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-20">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/qI8aB3Aq5Gc"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Architecture/Introduction to the <br /> Metaverse pt.II w/ Untitled XYZ</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-21">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/AIXfR"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Advanced Creative Coding w/ Sable Raph</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-22">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/61hqBGLt3NA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Arium Spaces Intro w/ Dan & Aidan</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-23">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/145u4h_iAf4"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Blender & Arium w/ Mical, Walt and Borya</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-24">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/wCB03aBGHj0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art Critique w/ Harry Martin</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-25">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/X_TTgKerzWE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>DAOs w/ Annie (IRL Art)</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-21">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/AIXfR"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Advanced Creative Coding w/ Sable Raph</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-26">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/pVSaNcWgldw"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>What are Smart Contracts <br /> and why are they important? w/ Isaac</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-27">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/DwtaqmqNWtM"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Architecture / Introduction to <br /> the Metaverse pt. II w/ Untitled XYZ</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-28">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/6mUv6vhKz5A"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA w/ Stefano Contiero</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12}  tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-32">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/9i5s_n1NMao"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Residency update and auction w/ Micol</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-29">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/Lp--b9mfl_U"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art Critique pt.II w Harry Martin</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-30">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/vOVKpppw1ds"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Learning how to mint a cables.gl patch <br/>on fx hash w/ Somaticbits</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12}  tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-31">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/0Raa8JGUuQQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA w Robness</h2>

    </div>


   

    <div class="container-video">
      <Tilt tiltMaxAngleX={12}  tiltMaxAngleY={12}>
        <div class="cardv" id="c3-video-33">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/kPPU7Io9D-U"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto wallets, security and Ledger setup</h2>

    </div>


    

  </div>
  <div class="video-next">
  <div class="button_slide slide_right" style={{ marginRight: '1em' }}><a href="/classes-c2">← COHORT 2</a></div>
    <div class="button_slide slide_right" style={{ marginLeft: '1em' }}><a href="/classes-c4">COHORT 4 →</a></div>

  </div>
</section>
    </>)
}
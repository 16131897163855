import React, { useEffect, useState } from 'react'
import Tilt from 'react-parallax-tilt'
import $ from 'jquery'

export const Classes = () => {
  const [viewing, setViewing] = useState(false);
  const [context, setContext] = useState(null);
  const [content, setContent] = useState(null);

  const handleViewClass = (event) => {
    let context = $(event.currentTarget).attr('data-lightbox-type');
    let content = $(event.currentTarget).attr('data-lightbox-content');

    setContext(context);
    setContent(content);
    setViewing(true);
  }

  useEffect(() => {
    if (viewing === true) {
      handlePassClass()
    }
  }, [viewing])

  const handlePassClass = (event) => {
    console.log(viewing)

    console.log('clicked');
    $('.lightbox').fadeToggle('fast');
  
    // console.log(event);
    if (context == 'video') {
      $('.lightbox-column').append(`
          <div class="lightbox-video">
          <iframe src="${content}" frameborder="0" allowfullscreen> </iframe>
          </div>
      `);
    } else if (context == 'image') {
      $('.lightbox-column').append(`
          <img src="${content}" class="img-" frameborder="0" allowfullscreen>
      `);
    }
  }

    return (<>

      {viewing && (
            <div class="lightbox" style={{ zIndex: 9999 }}>
            <a href="#lightbox" class="lightbox-close lightbox-toggle" onClick={() => setViewing(!viewing)}>X</a>
            <div class="lightbox-container">
              <div class="row">
                <div class="col-sm-12 lightbox-column">
                  
                </div>
              </div>
            </div>
          </div>
      )}

        <section id="main-alt-classes">
            <div class="container-classes-top">
                <div class="header-page w-50">
                    <h1>Residency </h1>
                    <h2>Classes</h2>
                </div>

                <div class="cohort-title">
                    <div class="cohort-item-1"><h3><span>C1</span></h3></div>
                    <a href="/classes-c2" class="cohort-item-2"><h3>C2</h3></a>
                    <a href="/classes-c3" class="cohort-item-2"><h3>C3</h3></a>
                    <a href="/classes-c4" class="cohort-item-2"><h3>C4</h3></a>
                    <a href="/classes-c5" class="cohort-item-2"><h3>C5</h3></a>
                    <a href="/classes-c5" class="cohort-item-2"><h3>C6</h3></a>
                </div>

            </div>
        </section>

<section id="classes-choice">
   <div class="video-list">

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-1">
          <a href="#lightbox" class="lightbox-toggle"
            data-lightbox-type="video"
            data-lightbox-content="https://www.youtube.com/embed/wLsvQBEZj9Q"
            onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Intro Call</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c1-video-2">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/wQcEL98dpfE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>CryptoArt History with Artnome</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c1-video-3">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/oEhWjyUlkp0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Museum Of Crypto Art</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-4">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/L71RBJG_nME"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>DADA.art</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-5">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/iJ27rHksnhs"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />     
        </div>
      </Tilt>
      <h2>Crypto Art Market History</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-6">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/kzpKlGMBCco"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Art Talk with Walt R. and Octavio I. </h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-7">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/PSUOVy2RJpc"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" /> 
        </div>
      </Tilt>
      <h2>Creative Coding with Raphaël de C.</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-8">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/Bvr6gS1QI8U"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Class with Sarah Zucker</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-9">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/R09rulofzdw"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Safety and Security with Reid Y.</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-10">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/v-2Z2D4otfg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Curation and Social Media Strategy</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-11">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/JFjVXbBKwZE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Creative Coding with Raphaël de C. p5js </h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-12">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/49zfTN_TbWE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA with Robness</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-13">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/0MarY8UQQMA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Metaverse with Arium</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-14">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/wtCFQ-L6gW0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Metaverse Architecture w/ UntitledXYZ</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-15">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/IRaEyswD7JQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Touch Designer w/ SomaticBits</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-16">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ykVXRwI762Y"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Touch Designer Intro w/ SOMATICBITS</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-17">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/JscE3DTgQkU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>DADA The Invisible Economy</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-18">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/BlpZ3-BMNsM"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Marketing, Promotion for Crypto Art w/ Esme</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-19">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/MMgI-Lo2_FY"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>VR/AR with Sam</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c1-video-20">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/8TGU0zHp4Kg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>DAOs w/ Louis @albiverse</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
      <div class="cardv" id="c1-video-21">
        <a href="#lightbox" class="lightbox-toggle"
        data-lightbox-type="video"
        data-lightbox-content="https://www.youtube.com/embed/Vmjv06JdjK4"
        onClick={(e) => handleViewClass(e)}
        />
        <div class="card-video" />
      </div>
      </Tilt>
      <h2>Touch Designer w/ SomaticBits</h2>

    </div>
   

  </div>
  <div class="video-next">
    <div class="button_slide slide_right"><a href="/classes-c2">→ COHORT 2</a></div>
  </div>
  </section>
    </>)
}
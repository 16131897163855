import { Home } from './pages/home'
import { Program } from './pages/program'
import { Classes } from './pages/classes'
import { ClassesC2 } from './pages/classes-c2'
import { ClassesC3 } from './pages/classes-c3'
import { ClassesC4 } from './pages/classes-c4'
import { ClassesC5 } from './pages/classes-c5'
import { ClassesC6 } from './pages/classes-c6'
import { Artists } from './pages/artists'
import { Mentors } from './pages/mentors'
import { Partners } from './pages/partners'
import { Donate } from './pages/donate'
import { Application } from './pages/application'
import { Join } from './pages/joinus'

export const routes = [
    {
        exact: true,
        path: '/',
        element: Home,
    },
    {
        exact: true,
        path: '/program',
        element: Program,
    },
    {
        exact: true,
        path: '/classes',
        element: Classes,
    },
    {
        exact: true,
        path: '/classes-c2',
        element: ClassesC2,
    },
    {
        exact: true,
        path: '/classes-c3',
        element: ClassesC3,
    },
    {
        exact: true,
        path: '/classes-c4',
        element: ClassesC4,
    },
    {
        exact: true,
        path: '/classes-c5',
        element: ClassesC5,
    },
    {
        exact: true,
        path: '/classes-c6',
        element: ClassesC6,
    },
    {
        exact: true,
        path: '/artists',
        element: Artists
    },
    {
        exact: true,
        path: '/mentors',
        element: Mentors
    },
    {
        exact: true,
        path: '/partners',
        element: Partners
    },
    {
        exact: true,
        path: '/donate',
        element: Donate
    },
    {
        exact: true,
        path: '/application',
        element: Application
    },
    {
        exact: true,
        path: '/joinus',
        element: Join
    }
]
import React, { useState, useEffect } from 'react'
import floatingButton from '../../img/badge/BTN-FLOATING.png'

import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'

import { BigNumber, ethers } from 'ethers'
import Web3Modal, { connectors } from 'web3modal'
import WalletConnectProvider from "@walletconnect/web3-provider"

import { BeaconWallet, BeaconWalletNotInitialized } from '@taquito/beacon-wallet'
import { TezosToolkit, OpKind } from '@taquito/taquito'

const axios = require('axios')

const Tezos = new TezosToolkit('https://mainnet.smartpy.io/')
const wallet = new BeaconWallet({
    name: 'verticalcrypto.art',
    preferredNetwork: 'mainnet',
})

Tezos.setWalletProvider(wallet)

const providerOptions = {
  walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: process.env.REACT_APP_INFURA_ID // required
      }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

const readProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`)

const getContractABI = async (contract) => 
  await axios.get(
      `https://api.etherscan.io/api?module=contract&action=getabi&address=${contract}&apikey=${process.env.REACT_APP_ETHERSCAN_ID}`
  )
  .then((res) => res.data.result)

export const Donate = () => {
  const [message, setMessage] = useState('')

  const [currency, setCurrency] = useState('ETH')
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    if (currency !== 'EUR' || amount <= 0) {
        return
    }

        // console.log('pp', amount)
    
        if (amount > 0) {
            // console.log('pp', amount)

            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    // amount: amount
                }
            })  
        }             
  }, [amount, currency])

  const [{ options }, dispatch] = usePayPalScriptReducer();

  const handleAmountChange = (e) => {
    console.log(e.target.value)
    setAmount(e.target.value)
  }

  const handleCurrencyChange = (e) => {
    console.log(e.target.value)
    setCurrency(e.target.value)
  }

  // ETH wallet
  const donateEthereum = async () => {
    setMessage('Processing Ether...')
    console.log(currency, amount)

    const provider = await web3Modal.connect();                
    const ethersProvider = new ethers.providers.Web3Provider(provider);
    const ethersSigner = ethersProvider.getSigner();

    const balance = await ethersSigner.getBalance()

    const cost = (await ethersProvider.getGasPrice()).toNumber() * (await ethersProvider.estimateGas({
      to: "0x8dC93807B66A0D47f165591848E8E49F99d6D5AF",
      value: ethers.utils.parseEther(amount)
    })).toNumber() + parseInt(ethers.utils.parseEther(amount))

    if (cost > balance) {
      setMessage('Insufficient funds')
      return

    } else {
      setMessage('Donating...')
    }

    console.log(cost, parseInt(balance))

    const data = {
      to: "0x8dC93807B66A0D47f165591848E8E49F99d6D5AF",
      value: ethers.utils.parseEther(amount)
    }

    const tx = await ethersSigner.sendTransaction(data)

    setMessage('Donation successful')
  }

  const donateXTZ = async () => {
    setMessage('Processing Tez...')

    const network = {
      type: 'mainnet',
      rpcUrl: 'https://mainnet.smartpy.io/',
    }

    await wallet.requestPermissions({ network })

    const acc = await wallet.client.getActiveAccount()

    setMessage('Donating...')

    const tx = await Tezos.wallet.transfer({ to: 'tz1WnbSkY2DWgJELqVxMe4uedEBre8mUDY68', amount: amount })
    const op = await tx.send()

    setMessage('Donation successful')
  }

  const createOrder = (data, actions) => {
    setMessage('Processing donation...')

    return actions.order.create({
      purchase_units: [
          {"amount":
            {
                "currency_code":currency,"value":amount,
                "breakdown":
                    {"item_total":
                        {"currency_code":currency,"value":amount}
                    }
                },
                "items":[
                    {"name":"item name","unit_amount":
                    {"currency_code":currency,"value":amount},
                    "quantity":"1","category":"DONATION"}
                ]
            }
        ]
    });
  }

  const onApprove = (data, actions) => {
    setMessage('Donation successful')
  }

  const onError = (err) => {
    console.log(err);
    setMessage('Error donating')
  }

  function onClick() {
    console.log(amount)
  }

  const clearAccounts = () => {
    web3Modal.clearCachedProvider()
    setMessage('Cleared')
  }

  const cancel = () => {
    setMessage('Donation cancelled')
  }

  const donate = async () => {
    // console.log(currency, amount)
    if (currency === 'ETH') {
      try {
        await donateEthereum()
      } catch (error) {
        console.log(error)
        setMessage('Error donating')
      }
    } else if (currency === 'XTZ') {
      try {
        await donateXTZ()
      } catch (error) {
        console.log(error)
        setMessage('Error donating')
      }
    } else if (currency === 'EUR') {
      createOrder()
    }
  }

    return (
        <section id="main-alt-2">
              <div class="container-donate-top">
    <div class="header-page">
      <h1>Support </h1>
      <h2>the Residency</h2>
    </div>
    <div class="donate-description">
      <p>VerticalCrypto Art Residency Program is a non-for-profit foundation. 
        All the educational programs we offer are free. <br/><br/>
        If you would like to support us with a donation you can do so through there.
        We accept crypto currencies (Ethereum and Tezos) and Fiat (through paypal). 
        </p>
    </div>

  </div>
 
  <div class="donate">
    <div class="sticker-arrow-donate ">
      <img src={floatingButton} />
    </div>
    <div class="donate-title">
      <h3>Donate</h3>
    </div>
    <div class="donate-form">
      <div class="align">

        <div class="grid">

          <form class="form login">
      
            <div class="form__field">
   
           
                <span class="hidden">Amount</span>
              <input type="number" class="form__input" placeholder="Amount" required onChange={(e) => handleAmountChange(e)} min={0} max={1e6}/>
            </div>
      
            <div class="form__field select__ccy">
              <select class="form__input" required onChange={(e) => handleCurrencyChange(e)}>
                <option value="ETH"> ETH </option>
                <option value="XTZ">TEZOS</option>
                {/* <option value="EUR">EURO</option> */}
              </select>
            
            </div>
      
            <div class="form__field donate_btn">
              {currency === 'EUR' ? (
                <PayPalButtons
                  className="paypal-button"
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                  onClick={onClick}
                  onCancel={cancel}
                  style={{ label: 'donate', height: 55, layout: 'horizontal', shape: 'pill', color: 'gold' }}
                />
              ) : (
                <button type="button" class="submit" onClick={() => amount && donate()}>Donate</button>
              )}
            </div>
          </form>
          <br />

          <div onClick={() => clearAccounts()}>Clear Accounts

          {message && (
            ' - ' + message
          )}

          </div>

      
        </div>

      </div>
    </div>
    
  </div>
        </section>
    )
}
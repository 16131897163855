import React from 'react'
import heroImage from '../../img/header/header-img-residency-2.jpg'

export const Application = () => {
    const svgTag = `
    <defs>
    <filter id="disFilter">
      <feTurbulence type="turbulence" baseFrequency="0.01" numOctaves="3" seed="1s" result="turbulence">
        <animate attributeName="baseFrequency" values="0.01;0.005;0.01;" dur="30s" begin="0" repeatCount="indefinite"></animate>
      </feTurbulence>
      <fedisplacementmap in="SourceGraphic" in2="turbulence" scale="30" xChannelSelector="R" yChannelSelector="B" result="displacement"></fedisplacementmap>
    </filter>
  </defs>
  <g id="background">
    <image xlink:href=${heroImage} x="0" y="0" height="100%" width="100%" preserveAspectRatio="xMidYMid slice"></image>
  </g>
    `

    return (
        <div>
            <div class="hero">
                <div class="header-home">
                    <h1>Residency <span>Program</span></h1>
                    <h2><span>Application</span></h2>
                    <p>Applications for our second 2022 cohort are now open. You can apply by completing our application form. </p>
                    <div class="apply-now">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScUOVqcqX1wyTqQtSHpKj33GR7YKtXzpEypGseIkBx0d-O9Gw/viewform?usp=sf_link" target="_blank"><div class="button_slide slide_right button_slide_apply">→ Apply now</div></a>
                    </div>
            

         
                </div>    
                
            </div>
            
    
        <svg id="svg-fixed" dangerouslySetInnerHTML={{__html: svgTag}}/>
    
      <section class="content-program" >
    
        <h2>Join our Residency Program</h2>
        
        <div class="program-details">
            <div class="program-category-title">
              <h3>How to apply?</h3>
            </div>
            <div class="program-category-description">
              <p>Applications for our second 2022 cohort are now open. You can apply by completing our application form.</p>

            </div>
          </div>
    
          <div class="program-details">
            <div class="program-category-title">
              <h3>How are applicants reviewed? </h3>
            </div>
            <div class="program-category-description">
              <p>Each application is reviewed by VerticalCrypto Art team and all the mentors of the program. We select applicants with inclusivity, diversity and equality ethos in mind and include creators worldwide and of a variety of different artistic backgrounds and practices. </p>
                
            </div>
          </div>
    
        
        
        <div class="program-note">
        
          <p>FOLLOW US ON TWITTER, INSTAGRAM AND DISCORD TO BE KEPT IN THE LOOP.</p>
          <br/><br/>
        </div>
        
         
        </section>
        </div>
    )
}
import React from 'react'
import styles from './styles.module.scss'

export const Join = () => {
    return (
        <section id="main-alt-2">

  <div class="container-join-top">
    <div class="header-page ">
      <h1>Join </h1>
      <h2>the Residency</h2>
    </div>
    <div class="donate-description">
      <p>Join the program as an artist during our open calls, as a mentor sharing your know-how, and as a partner supporting the program. Find out how below.</p>
    </div>

  </div>

  <div class="container-join">

      <div class="join-category">
        <div class="join-category-title">
          <h2>Apply as an artist</h2>
        </div>
        <div class="join-category-description">
        <p><a href="https://docs.google.com/forms/d/e/1FAIpQLScUOVqcqX1wyTqQtSHpKj33GR7YKtXzpEypGseIkBx0d-O9Gw/viewform?usp=sf_link" target="_blank">Apply now</a></p>
        </div>
      </div>

      <div class="join-category">
        <div class="join-category-title">
          <h2>Join as a mentor</h2>
        </div>
        <div class="join-category-description">
          <p><a href="mailto:residency@verticalcrypto.art?subject=Join as a mentor" target="_blank">Contact us</a></p>
        </div>
      </div>

      <div class="join-category">
        <div class="join-category-title">
          <h2>Partner with us</h2>
        </div>
        <div class="join-category-description">
          <a href="mailto:residency@verticalcrypto.art?subject=Partner with us" target="_blank"><p>Contact us</p></a>
        </div>
      </div>


  </div>
  </section>
    )
}
import React, { useState } from 'react'
import hamburger from '../../img/icons/hamburger.svg'
import close from '../../img/icons/cross-light.svg'
import styles from './styles.module.scss'

export const Menu = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    return (<>
            <div className={`${"menu-overlay"} ${isOpen ? styles.visible : styles.invisible}`}>
                <ul className="nav-menu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/program">Program</a></li>
                    <li><a href="/classes-c4">Classes</a></li>
                    <li><a href="/artists">Artists</a></li>
                    <li><a href="/mentors">Mentors</a></li>
                    <li><a href="https://arium.xyz/spaces/vca" target="_blank">Gallery</a></li>
                    <li><a href="/partners">Partners</a></li>
                    <li><a href="/donate">Donate</a></li>
                </ul>
        
                <div className="close" onClick={() => handleClick()}><a href="#"><img src={close} alt="" /></a></div>
            </div>

            <a href="#" onClick={() => handleClick()}><div class="button_slide slide_right slide_transparent"><img id="hamburger" src={hamburger} /> Menu</div></a>
    </>)
}
import React from "react";
import "./Modal.css";

function Modal({ setOpenModal, mentorId}) {

 
  const mentorInfo = [

    { 
      id: 1,
      name: "Artnome-Jason Bailey",
      title:"Art Nerd, NFT OG, CEO and Cofounder of ClubNFT",
      class: "History of Crypto Art",
      descriptionq1: "How did you get involved with Vertical Crypto?",
      descriptionr1: "I fell in love with Micol because she is exactly the kind of person who makes the Crypto Art space a great place to be. I saw her supporting artists and building a community around Hic et Nunc in early 2021 and I knew I wanted to collaborate with her if the chance ever came up. When she asked if I'd participate in the digital artist's residency as a mentor I was flattered and thrilled to be a part of it.",
      descriptionq2: "What was your experience like on the residency program? ",
      descriptionr2: "The residency program has been great. Selfishly, I always ask all the incoming artists to tell me a bit about themselves because I have an insatiable appetite for art and I love getting to make new friends and having the inside track on up-and-coming artists from around the world. I teach a course on Crypto Art history and really enjoy sharing my perspective with folks who maybe don't always realise what came before 2021. I've also done a dozen or so one on one mentoring sessions with individual artists from the program. I almost always find I learn more from them than they do from me. It's been a lot of fun!",
      descriptionq3: "If you were a participant (rather than mentor) on our programmes, what questions would you be asking right now?",
      descriptionr3: "I'd be asking if I'm too late to the NFT market. I think so many artists struggle with selling NFTs and they start to think there must be something wrong with them or their art. The truth is the best way has always been to engage with the community and the sales may (or may not) come later.",
    
    },
    { 
      id: 2,
      name: "Fanny Lakoubay",
      title:"Founder and CEO of LAL Art",
      class: "NFT Market",
      descriptionq1: "How did you get involved with Vertical Crypto?",
      descriptionr1: "I first got in touch with Micol as there were not that many crypto art advisors and curators around. During the pandemic, we hopped on a call, and connected immediately on our vision of the space and our will to support artists. To this day, we collaborate on many initiatives, and I enjoyed teaching some of the VCA classes and becoming a mentor of the program.",
      descriptionq2: "What was your experience like on the residency program? ",
      descriptionr2: "As a mentor of VCA, I have enjoyed meeting new artists, discovering their practice and watching them grow. It is easy to rely on big artist names and forget about what is really important: helping more artists join the space and guiding them in their NFT journey. We are all building something new and initiatives like VCA allow for more coordination within the space to help us move forward without recentralizing our efforts.",
      descriptionq3: "If you were a participant (rather than mentor) on our programmes, what questions would you be asking right now?",
      descriptionr3: "If I was a participant, I'd ask the mentors their advice to stay sane in this ever-changing industry!",
    
    },
    { 
      id: 3,
      name: "Sarah Zucker",
      title:"Artist and writer, based in LA",
      class: "AMA",
      descriptionq1: "What was your experience like as a mentor?",
      descriptionr1: "It was lovely to get to chat with artists who are orienting themselves to crypto art, from a wide background of disciplines and career development. I'm happy to share my experience as an early participant in NFT culture to help others find their way. Being a mentor for the VCA residency has affirmed my belief that there's no single right way to approach this emerging space, and that being a self-starter and leaning into your individual voice is the most viable path for any artist here.",
      descriptionq2: "What would your advice be to an artist starting out today?",
      descriptionr2: "Find your people. Your community and relationships will be your guiding light, especially when the larger culture becomes volatile. Learn from others' successes and apply what you see working to your own approach, but remember that your art is uniquely your own, and you have to forge your own path. Develop organic connections with collectors through twitter or elsewhere. Spamming people who don't know you is not a shrewd way to make an introduction. Be authentic, be patient, be kind... and don't be gross.",
      descriptionq3: "",
      descriptionr3: "",
    
    },
    { 
      id: 4,
      name: "Judy Mam [DADA]",
      title:"Founder and CEO of DADA.art",
      class: "The Invisible Economy",
      descriptionq1: "What was your experience like on the residency program?",
      descriptionr1: "I have met several artists with whom I personally, and DADA, have formed friendships. It is always interesting to hear from artists getting into the space and it feels good to be able to provide some guidance. ",
      descriptionq2: "If you were a participant (rather than mentor) on our programmes, what questions would you be asking right now?",
      descriptionr2: "How to keep my sanity and creativity in this space? What alternative communities or projects exist? What can we do to make it a better space for everybody? What resources are there for crypto artists beyond marketplaces and promotional platforms?",
      descriptionq3: "",
      descriptionr3: "",
    },
    { 
      id: 5,
      name: "Samuel Arsenault-Brassard",
      title:"XR Artist and Curator",
      class: "AR/VR",
      descriptionq1: "How did you get involved with Vertical Crypto?",
      descriptionr1: "I noticed Micol's as an NFT curator when I saw her wonderful curation videos using old TVs and projectors. She was hosting discussions about digital curation on Clubhouse at the time and I was curating VR and NFTs at ELLEPHANT and Art Mûr gallery in Montréal. I reached out and let her know I was interested in talking about the topic. She mentioned an upcoming mentorship program and asked if I'd be interested to give mentorship sessions on VR and AR -I've been participating ever since.",
      descriptionq2: "What was your experience like on the residency program? ",
      descriptionr2: "When talking to the groups, I could give pretty broad presentations about VR, AR and the metaverse, then delve deeper into topics that individuals wanted to discuss. It's exciting to get a response from so many motivated art students and answer their questions in real time. I was also able to meet Murat Atimtay and Mical Noelson and learn about their art practice in depth, as I was already a big fan of their work.",
      descriptionq3: "If you were a participant (rather than mentor) on our programmes, what questions would you be asking right now?",
      descriptionr3: "I'd be asking for help with lightfields, Neural Radiance Fields (NeRF) and on integrating smart contracts into art narratives, for example a 3D scene that is populated differently based on what NFTs the collector has, or a scene that reacts to the sale and transfer of peripheral NFTs. I'd also try to speak to the mentors whose art trajectories I'm most intrigued with.",
    
    },
    { 
      id: 6,
      name: "Colborn Bell",
      title:"Founder of the Museum of Crypto Art",
      class: "Museum of Crypto Art w/Colborn and Shivani Mitra",
      descriptionq1: "How did you get involved with Vertical Crypto?",
      descriptionr1: "When VCA joined the crypto art scene, Micol immediately stood out as someone incredibly passionate about the art, telling the stories of artists, and recognizing that there was an important and powerful narrative here. It was rare to find such a kindred spirit and our aligned interests intuitively brought us together. She reached out on February 25, 2021, I subsequently sent her a gift of Magic Internet Money and the rest is history...",
      descriptionq2: "What was your experience like on the residency program? ",
      descriptionr2: "I can't stress how vitally important it is to keep a pulse on the emerging artists in the crypto art field as they bring powerful new ideas and a wide-eyed optimism as to what the space could be. In my experience of running the Incubator of Crypto Art, it became so immediate how many passionate people wanted to reflect on how crypto art inspires them and transforms the narrative around digital artwork and its value. The residency is a more powerful and evolved version of this project and holds a wide breadth of knowledge and experience with its mentors. This diversity of thought and culture is paramount in showcasing the power of this art movement.",
      descriptionq3: "",
      descriptionr3: "",
    }


  ]

  return (
    <div className="modalBackground">
      <div className="modalContainer">
       
        <div className="title-modal">
          <h1>VCA Mentor Spotlight #{mentorInfo[mentorId].id}</h1>
          <h2><span>Name:</span> {mentorInfo[mentorId].name} <br/>
          <span>Title:</span>  {mentorInfo[mentorId].title}<br/>
          <span>VCA Residency Class:</span>  {mentorInfo[mentorId].class}</h2>
        </div>
        <div className="body-modal">
          <p><span>{mentorInfo[mentorId].descriptionq1}</span> <br/><br/>
          {mentorInfo[mentorId].descriptionr1}<br/><br/>

<span>{mentorInfo[mentorId].descriptionq2}</span> <br/><br/>

{mentorInfo[mentorId].descriptionr2} <br/><br/>


<span>{mentorInfo[mentorId].descriptionq3}</span> <br/>
{mentorInfo[mentorId].descriptionr3} 

</p>
        </div>
        <div className="footer-modal">
        <div class="button_slide slide_right" onClick={() => {
              setOpenModal({bool:false,mentorId:0});
            }}>← Go back</div>

        </div>
      </div>
    </div>
  );
}

export default Modal;

import React, { useEffect, useState } from 'react'
import Tilt from 'react-parallax-tilt'
import $ from 'jquery'

const checkPass = (pass) => {
  if (pass === 'vertical21-22resident') {
    return true
  } else {
    return false
  }
}

export const ClassesC6 = () => {
  const [viewing, setViewing] = useState(false);
  const [context, setContext] = useState(null);
  const [content, setContent] = useState(null);

  const handleViewClass = (event) => {
    let context = $(event.currentTarget).attr('data-lightbox-type');
    let content = $(event.currentTarget).attr('data-lightbox-content');

    setContext(context);
    setContent(content);
    setViewing(true);
  }

  useEffect(() => {
    if (viewing === true) {
      handlePassClass()
    }
  }, [viewing])

  const handlePassClass = (event) => {
    console.log(viewing)

    console.log('clicked');
    $('.lightbox').fadeToggle('fast');
  
    // console.log(event);
    if (context == 'video') {
      $('.lightbox-column').append(`
          <div class="lightbox-video">
          <iframe src="${content}" frameborder="0" allowfullscreen> </iframe>
          </div>
      `);
    } else if (context == 'image') {
      $('.lightbox-column').append(`
          <img src="${content}" class="img-" frameborder="0" allowfullscreen>
      `);
    }
  }

    return (<>

      {viewing && (
            <div class="lightbox" style={{ zIndex: 9999 }}>
            <a href="#lightbox" class="lightbox-close lightbox-toggle" onClick={() => setViewing(false)}>X</a>
            <div class="lightbox-container">
              <div class="row">
                <div class="col-sm-12 lightbox-column">
                  
                </div>
              </div>
            </div>
          </div>
      )}

        <section id="main-alt-classes">

  <div class="container-classes-top">
    <div class="header-page w-50">
      <h1>Residency </h1>
      <h2>Classes</h2>
    </div>
    <div class="cohort-title">

      <a href="/classes" class="cohort-item-1"><h3>C1</h3></a>
      <a href="/classes-c2" class="cohort-item-2"><h3>C2</h3></a>
      <a href="/classes-c3" class="cohort-item-2"><h3>C3</h3></a>
      <a href="/classes-c4" class="cohort-item-2"><h3>C4</h3></a>
      <a href="/classes-c5" class="cohort-item-2"><h3>C4</h3></a>
      <div class="cohort-item-2"><h3><span>C6</span></h3></div>

    </div>
  </div>
</section>

<section id="classes-choice">
   <div class="video-list">
   <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-1">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/U5rBqrPTtZw"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Welcome & Introduction</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-2">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/UnftjF0qfWE"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>History of Crypto Art</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-3">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/XMz4-9jyJ0w"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Curation of Crypto Art</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-4">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/o3vTy3bciDg"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>The Edicurial Collection: <br />Everything you always wanted to know</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-5">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/BoS9raBUddU"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>How does the NFT market work?</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-6">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/5HbAHwiMLX8"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Nicolas Sassoon</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-7">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/VsAF-xDBT60"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Social media strategy in the NFT space</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-9">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/PcKH2agGZb0"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Crypto Ecosystems and DeFi</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-10">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/zpN2A5SvJnY"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Art of NFT Construction</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-11">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/hSt9crJSKxA"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Art After NFTs</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-12">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/o17gwbshxWo"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Crypto Art Critique #1</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-13">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/r6zSor_jksk"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>DAOs and art related organisations<br />in a decentralised ecosystem</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-14">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ipJVPh_SiHI"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Curating Generative Art</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-15">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/8jeLGAdpaFM"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with William Mapan</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-16">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/hJiIGo-ni5A"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Museums, Art History and<br />Digital Art in the context of NFTs</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-17">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/S4BNqZWXP5U"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Crypto Art Critique #2</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-18">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/_e4ZfAuaM_Q"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with P1xelFool</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-19">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/gGZBfpkPrPY"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Gallery Structures</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-20">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/Ib6stlQwdhQ"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Residency Check in with Micol</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-21">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/AzbfG5DAFuw"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Safety and Security for artists in the NFT space</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-22">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/QT-axiWJQ3k"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>What are smart contracts and<br/>why are they important</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-23">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/w06DMuOEvWM"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Installing a hardware wallet<br />and securing your assets</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-24">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/JIncycWCeMc"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Dr Tina Rivers Ryan</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-25">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/sXV86KOkH2I"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Creative Coding First Steps With p5js</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-26">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/lZZ8wVDE5X4"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Patrick Amadon</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-27">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ieLYUYsdmvE"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Intro to AI</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-28">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/dhAL2D4chGU"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with UntitledXYZ</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-29">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/PssijGExYJU"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Creative Coding - Introduction to Generative Art</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-30">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/VmL0RZk8CoA"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Crypto Art Critique #3</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-31">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/Woi3Xk7zG8o"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Architecture in the Metaverse</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-32">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/BdfojgIZrT0"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Kaloh</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-33">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/DfoaWNhuH_U"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Zancan</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-34">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/LAPb7SNssmY"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Curation Q&A with Aleksandra Art</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-35">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/C_GXMeM7lK4"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Workshop on Blender</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-36">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/P5VuOb-XuoU"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Crypto Art Critique #4</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-37">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/t7EP4aHQz1g"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Strategies for setting up your marketplace</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-38">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/_Xwcq3qdHDA"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Dina Chang</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-39">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/15NN9wkc2es"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Q&A on AR and VR practices</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-40">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/-jNucGD2A7U"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Residency Update Auction &<br />Exhibition with Micol</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-41">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/t7MVDxM8u5E"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>How to build your own website</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-42">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ZwWk1zMy-1E"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Anika Meier</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-43">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/fQid5n_9b10"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with Greekdx</h2>
    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c6-video-44">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/P1v77sDP2Jk"         
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>AMA with The Funny Guys</h2>
    </div>


  </div>
  <div class="video-next">
    <div class="button_slide slide_right"><a href="/classes-c5">← COHORT 5</a></div>
  </div>
</section>
    </>)
}
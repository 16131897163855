import React, { useEffect, useState } from 'react'
import Tilt from 'react-parallax-tilt'
import $ from 'jquery'

const checkPass = (pass) => {
  if (pass === 'vertical21-22resident') {
    return true
  } else {
    return false
  }
}

export const ClassesC2 = () => {
  const [viewing, setViewing] = useState(false);
  const [context, setContext] = useState(null);
  const [content, setContent] = useState(null);

  const [allowed, setAllowed] = useState(true);

  const handleViewClass = (event) => {
    let context = $(event.currentTarget).attr('data-lightbox-type');
    let content = $(event.currentTarget).attr('data-lightbox-content');

    setContext(context);
    setContent(content);
    setViewing(true);
  }

  useEffect(() => {
    if (viewing === true) {
      handlePassClass()
    }
  }, [viewing])

  const handlePassClass = (event) => {
    console.log(viewing)

    console.log('clicked');
    $('.lightbox').fadeToggle('fast');
  
    // console.log(event);
    if (context == 'video') {
      $('.lightbox-column').append(`
          <div class="lightbox-video">
          <iframe src="${content}" frameborder="0" allowfullscreen> </iframe>
          </div>
      `);
    } else if (context == 'image') {
      $('.lightbox-column').append(`
          <img src="${content}" class="img-" frameborder="0" allowfullscreen>
      `);
    }
  }

  const handleCheckPassword = (entry) => {
    console.log(entry);
    if (checkPass(entry)) {
      setAllowed(true);
    }
  }

  if (!allowed) {
    return (<>
      <section id="main-alt-classes">
          <div class="container-classes-top">
    <div class="header-page w-50">
      <h1>Residency </h1>
      <h2>Classes</h2>
    </div>
    {/* <div class="cohort-title">
      <div class="cohort-item-1" style={{ display: 'flex', alignItems:'center' }}><h3>PASSWORD:</h3><input onChange={(e) => handleCheckPassword(e.target.value)} type="text" placeholder=' Enter the password' style={{ height: '2.5em', marginLeft: '1em', background: '#CCCCCC' }}/>
      </div>

  
    </div> */}

  </div>
      </section>

<section id="classes-choice">
  <div class="video-list">
    <div class="video-item">
      <div class="cardv" style={{ opacity: 0 }}>
        <div class="card-video"/>
      </div>
    </div>
  </div>
</section>
    </>)
  }

    return (<>

      {viewing && (
            <div class="lightbox" style={{ zIndex: 9999 }}>
            <a href="#lightbox" class="lightbox-close lightbox-toggle" onClick={() => setViewing(false)}>X</a>
            <div class="lightbox-container">
              <div class="row">
                <div class="col-sm-12 lightbox-column">
                  
                </div>
              </div>
            </div>
          </div>
      )}

        <section id="main-alt-classes">

  <div class="container-classes-top">
    <div class="header-page w-50">
      <h1>Residency </h1>
      <h2>Classes</h2>
    </div>
    <div class="cohort-title">
      <a href="/classes" class="cohort-item-1"><h3>C1</h3></a>
      <div class="cohort-item-2"><h3><span>C2</span></h3></div>
      <a href="/classes-c3" class="cohort-item-2"><h3>C3</h3></a>
      <a href="/classes-c4" class="cohort-item-2"><h3>C4</h3></a>
      <a href="/classes-c5" class="cohort-item-2"><h3>C5</h3></a>
      <a href="/classes-c6" class="cohort-item-2"><h3>C6</h3></a>
    </div>
  </div>
</section>

<section id="classes-choice">
   <div class="video-list">
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-1">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/_7Vl-dFmj80"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
          </div>
        </Tilt>
        <h2>Intro Call</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c2-video-2">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/ZY-VformTig"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Crypto Art History with Artnome</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv"  id="c2-video-3">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/JkhgHQK1OEw"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>CryptoArt in Context with MLO.art</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-4">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/tGPXY-1XZTU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>How does the NFT market work? w/ Fanny L.</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-5">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/T88qZLFioP0"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />     
        </div>
      </Tilt>
      <h2>Crypto EcoSystems and DeFi with Jason Dukes</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-6">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/reuBPD8bcYA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Museums, Digital Art and NFTs with Bernadine B.</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-7">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/CekWfGn62zE"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" /> 
        </div>
      </Tilt>
      <h2>Marketing & Promotion with Esmay</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-8">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/wU00D3Ryr-E"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA w/ Iskra (PointLine) and Msorario</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-9">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/cz7zIAjqEZU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Metaverse Architecture 1st Class</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-10">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/wFHCCqSqszA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Museum of Crypto Art w/ Colborn and Shivani</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-11">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/2UXRgybYS1c"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Ask Me Anything with Carlos Marcial</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-12">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/X3kJ0JU6d4E"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Creative Coding Intro with Sable Raph</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-13">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/47CF4PBFDU4"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Arium Spaces with Dan & Aidan</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-14">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/_y8X-9mUaFg"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>TouchDesigner Intro & Basics with SomaticBits</h2>

    </div>
    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-15">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/SA1oZnsasAc"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Safety & Security for Digital Assets</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-16">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/pJnTdcH9wpI"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Metaverse Architecture 2 w/ Kirk</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-17">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/kZmPY8jrYGA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>AMA w/ Bryan Brinkman</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-18">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/WKOgoDoP8m4"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>The Invisible Economy w/ DADA</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-19">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/zWmSqjEi93M"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Basics with SomaticBits</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-20">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/uQJ5j06Io3c"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Ledger Set Up Tutorial</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-24">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/H8bErPd3rok"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>A Crypto Art Critique Class w/ Harry Martin</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-21">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/f111PIpInwU"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Making My Own NFTs & Smart Contracts</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-25">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/fb93-hll4Ec"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>VCA Critique #2</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-22">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/5WO3916HOJQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>VR & AR with Sam</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-27">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/NaOUBYKHMVA"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Update & Chat w/ Micol</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-26">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/IECXWHRydPQ"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Twitter Spaces: Artist Spotlight & AMA</h2>

    </div>

    <div class="container-video">
      <Tilt tiltMaxAngleX={12} tiltMaxAngleY={12}>
        <div class="cardv" id="c2-video-23">
          <a href="#lightbox" class="lightbox-toggle"
          data-lightbox-type="video"
          data-lightbox-content="https://www.youtube.com/embed/kctM_-8Hj2A"
          onClick={(e) => handleViewClass(e)}
          />
          <div class="card-video" />
        </div>
      </Tilt>
      <h2>Ask Me Anything w/ Robness</h2>

    </div>
  </div>
  <div class="video-next">
    <div class="button_slide slide_right" style={{ marginRight: '1em' }}><a href="/classes">← COHORT 1</a></div>
    <div class="button_slide slide_right" style={{ marginLeft: '1em' }}><a href="/classes-c3">COHORT 3 →</a></div>
  </div>
</section>
    </>)
}
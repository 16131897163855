import React, { useState } from 'react'
import styles from './styles.module.scss'
import $ from 'jquery'
import Modal from '../../components/modal/Modal'

export const Mentors = () => {

  const [modalOpen, setModalOpen] = useState ({
    bool:false,
    mentorId:0
    })

  const filter = (e) => {
    var category = e.target.innerText

    $('.work-list li').removeClass('active');
    $(e.target).toggleClass('active');
  
    if(category == 'ALL'){
      $(".mentor-unit").show(0);
    }else{
      $('.mentor-unit').hide();
      $('.mentor-unit').filter('.'+category).show();
    }
}

return (
    <section id="main-alt-artists">
<div class="title-cohort"> <h1>MENTORS</h1></div>
<ul class='work-list'>
<li class='active' onClick={(e) => filter(e)}>ALL</li>
<li onClick={(e) => filter(e)}>C1</li>
<li onClick={(e) => filter(e)}>C2</li>
<li onClick={(e) => filter(e)}>C3</li>
<li onClick={(e) => filter(e)}>C4</li>
<li onClick={(e) => filter(e)}>C5</li>
</ul>
<div class='work-content'>

<div class='mentor-unit C1 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Beatriz Ramos</h1>
   
  </div>
</div>

<a href="https://twitter.com/Bjkbrocker" target="_blank" rel="noreferrer">
<div class='mentor-unit C1 C2 C4 C5 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Bernadine.png" alt="" />
  <div class="artist-overlay">
    <h1>Bernadine Brocker Wieder </h1>
  
  </div>
</div>
</a>

<div class='mentor-unit C1 C2 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt=""/>
  <div class="artist-overlay">
    <h1>David Lisser</h1>
   
  </div>
</div>
<div class='mentor-unit C1 C2 C3 C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-8.jpg" alt="" />
  <div class="artist-overlay">
    <h1>David Pettersson / SOMATIC BITS</h1>
  </div>
</div>

<a href="https://twitter.com/dianedrubay" target="_blank" rel="noreferrer">
 <div class='mentor-unit C2 C4 C5 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Diane.png" alt="" />
  <div class="artist-overlay">
    <h1>Diane Drubay</h1>
  </div>
 </div>
</a>

<div class='mentor-unit C1 C2 C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Domenico.png" alt="" />
  <div class="artist-overlay">
    <h1>Domenico Barra</h1>
  
  </div>
</div>
<div class='mentor-unit C2 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
 
  <div class="artist-overlay">
    <h1>Esmay Luckhille</h1>

  </div>

</div>
<div class='mentor-unit C2 C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Fabin.png" alt="" />
  <div class="artist-overlay">
    <h1>Fabin Rasheed</h1>
  
  </div>
</div>

<div class='mentor-unit C1 C2 C3 C4 C5 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-4.jpg" alt="" />
  <div class="artist-overlay">
    <h1>Fanny Lakoubay</h1>
    <button onClick={()=>setModalOpen({bool:true,mentorId:1})}>Learn More</button>
  </div>
</div>


<a href="https://twitter.com/martjpg" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Harry%20Martin.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Harry Martin</h1>
    
    </div>

  </div>
</a>


<a href="https://twitter.com/hdelhumeau" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Herve%20Delhumeau.JPG" alt="" />
    <div class="artist-overlay">
      <h1>Hervé Delhumeau</h1>
    
    </div>
  </div>
</a>

<div class='mentor-unit C1 C2 C3 C4 C5 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-10.jpg" alt="" />

  <div class="artist-overlay">
    <h1>Artnome - Jason Bailey</h1>
    <button onClick={()=>setModalOpen({bool:true,mentorId:0})}>Learn More</button>
    
  </div>
</div>

<a href="https://twitter.com/LiveSent" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C3 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-3.jpg" alt="" />

    <div class="artist-overlay">
      <h1>Jason Dukes</h1>

    </div>

  </div>
</a>

<div class='mentor-unit C1 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />

  <div class="artist-overlay">
    <h1>Jatin Pathi/Evil</h1>
 
  </div>

</div>
<div class='mentor-unit C1 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Judy Mam</h1>
   
  </div>
</div>

<a href="https://twitter.com/KalenIwamoto" target="_blank" rel="noreferrer">
  <div class='mentor-unit C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Kalen%20Iwamoto.png" alt="" />

    <div class="artist-overlay">
      <h1>Kalen Iwamoto</h1>
  
    </div>

  </div>
</a>


<div class='mentor-unit C1 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />

  <div class="artist-overlay">
    <h1>Karen Eng</h1>
  
  </div>

</div>

<a href="https://twitter.com/untitledxyz_" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Kirk%20Finkel%20%28untitledxyz%29.png" alt="" />
    <div class="artist-overlay">
      <h1>Kirk Finkel (untitled, xyz)</h1>
    
    </div>
  </div>
</a>

<div class='mentor-unit C1 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />

  <div class="artist-overlay">
    <h1>Lapin Mignon</h1>
   
  </div>

</div>
<div class='mentor-unit C1 C2 fill'>
  
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Lisa Kolb</h1>
  
  </div>
</div>

<a href="https://twitter.com/MLOdotArt" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Martin%20Lukas%20Ostachowski.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Martin Lukas Ostachowski</h1>
    
    </div>
  </div>
</a>


<a href="https://twitter.com/patrickamadon" target="_blank" rel="noreferrer">

  <div class='mentor-unit C1 C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Patrick%20Amadon.jpg" alt="" />
    
    <div class="artist-overlay">
      <h1>Patrick Amadon</h1>

    </div>
  </div>
</a>


<div class='mentor-unit C1 C2 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Patrick Tresset</h1>
   
  </div>
</div>

<a href="https://twitter.com/sableraph" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C3 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Sableraph_Raphael_de_Courville.png" alt="" />
    <div class="artist-overlay">
      <h1>Raphaël de Courville (sableRaph)</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/in_cloudlands" target="_blank" rel="noreferrer">
  <div class='mentor-unit C2 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Rose%20Jackson.jpg" alt="" />
    
    <div class="artist-overlay">
      <h1>Rose Jackson</h1>
    
    </div>

  </div>
</a>

<a href="https://twitter.com/nft__collective" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Ryan%20Roybal%20%28NFT%20collective%29.png" alt="" />
    <div class="artist-overlay">
      <h1>Ryan Roybal</h1>
  
    </div>
  </div>
</a>

<div class='mentor-unit C1 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />

  <div class="artist-overlay">
    <h1>Sam Brukhman</h1>

  </div>

</div>
<div class='mentor-unit C1 C2 C3 C4 C5 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-5.jpg" alt="" />
  <div class="artist-overlay">
    <h1>Samuel Arsenault-Brassard</h1>
    <button onClick={()=>setModalOpen({bool:true,mentorId:4})}>Learn More</button>
  </div>

</div>
<div class='mentor-unit C2 C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Sarah Moosvi</h1>

  </div>
</div>

<a href="https://twitter.com/sofiagarcia_io" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/sofia%20garcia.jpg" alt="" />
    
    <div class="artist-overlay">
      <h1>Sofia Garcia</h1>

    </div>

  </div>
</a>

<div class='mentor-unit C1 C2 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Sparrow</h1>
 
  </div>

</div>

<a href="https://twitter.com/stinalinneag" target="_blank" rel="noreferrer">
  <div class='mentor-unit C2 C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Stina%20Gustafsson.jpg" alt="" />

    <div class="artist-overlay">
      <h1>Stina Gustafsson</h1>

    </div>

  </div>
</a>

<div class='mentor-unit C1 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Vanessa Coleman</h1>

  </div>
</div>

<div class='mentor-unit C1 C2 C3 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-2.jpg" alt="" />
  <div class="artist-overlay">
    <h1>Sarah Zucker</h1>
    <button onClick={()=>setModalOpen({bool:true,mentorId:2})}>Learn More</button>
  </div>
</div>

<div class='mentor-unit C1 C2 C3 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-6.jpg" alt="" />
  <div class="artist-overlay">
    <h1>MOCA</h1>
    <button onClick={()=>setModalOpen({bool:true,mentorId:5})}>Learn More</button>
  </div>
</div>

<a href="https://twitter.com/PowerDada" target="_blank" rel="noreferrer">
  <div class='mentor-unit C1 C2 C3 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-7.jpg" alt="" />
    <div class="artist-overlay">
      <h1>DADA</h1>
      <button onClick={()=>setModalOpen({bool:true,mentorId:3})}>Learn More</button>
    </div>
  </div>
</a>

<div class='mentor-unit C1 C2 C3 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmX32wyUkRZwgkn71KcEsJQHkmnVFkNY85Nj72MJeNDoU6/VCA_MENTORS_DRAFT_LIGHT-9.jpg" alt="" />
  <div class="artist-overlay">
    <h1>Arium Spaces</h1>
 
  </div>
</div>

<div class='mentor-unit C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Aidan Nelson</h1>
   
  </div>
</div>

<div class='mentor-unit C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Annie (IRL Art)</h1>
   
  </div>
</div>

<a href="https://twitter.com/boryaxyz" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/BoryaXYZ.png" alt="" />
    <div class="artist-overlay">
      <h1>BoryaXYZ</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/ForcesHidden" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/BradleyWilson.png" alt="" />
    <div class="artist-overlay">
      <h1>Bradley Wilson - Hidden Forces</h1>
    
    </div>
  </div>
</a>

<div class='mentor-unit C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Colborn.png" alt="" />
  <div class="artist-overlay">
    <h1>Colborn Bell</h1>
   
  </div>
</div>

<div class='mentor-unit C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Dominic - petitsapin</h1>
   
  </div>
</div>

<a href="https://twitter.com/EmpressTrash" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Empress%20Trash.png" alt="" />
    <div class="artist-overlay">
      <h1>Empress Trash</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/@enaraxr" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Enara.png" alt="" />
    <div class="artist-overlay">
      <h1>Enara Nazarova</h1>
    
    </div>
  </div>
</a>

<div class='mentor-unit C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Esmay.png" alt="" />
  <div class="artist-overlay">
    <h1>Esmay Luck-Hille</h1>
   
  </div>
</div>

<a href="https://twitter.com/hypereikon" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/hypereikon.png" alt="" />
    <div class="artist-overlay">
      <h1>hypereikon</h1>
    </div>
  </div>
</a>

<div class='mentor-unit C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/Qmc9vfyHGjAhATJ1aqBgepmA8U13FXD4Ke3xq6YT8JpUQk" alt="" />
  <div class="artist-overlay">
    <h1>Ilan Katin</h1>
   
  </div>
</div>

<a href="https://twitter.com/isaacpatka" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Isaac%20Patka.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Isaac Patka</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/ivonatau" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Ivona%20Tau.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Ivona Tau</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/MaylinPerez_" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Maylin%20Pérez.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Maylin Pérez</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/micalnoelson" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Mical%20Noelson.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Mical Noelson</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/mikirishi" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Miki%20Elson%20FRSA.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Miki Elson FRSA</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/atimtay" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Murat%20Atimtay.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Murat Atimtay</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/pgervois" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Pierre%20Gervois.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Pierre Gervois</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/Reid_Yager" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Reid%20Yager.png" alt="" />
    <div class="artist-overlay">
      <h1>Reid Yager</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/neymrqz" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Santiago%20Márquez.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Santiago Márquez</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/victoriafard" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/VictoriaFard.png" alt="" />
    <div class="artist-overlay">
      <h1>Victoria Fard</h1>
    
    </div>
  </div>
</a>

<div class='mentor-unit C4 fill'>
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/Walt.png" alt="" />
  <div class="artist-overlay">
    <h1>Walt</h1>
   
  </div>
</div>

<a href="https://twitter.com/zareenfava" target="_blank" rel="noreferrer">
  <div class='mentor-unit C4 C5 fill'>
    <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmdMdd3GGkB6Y7Rdi5zKESJJx8hp4YR2nxsmq5gEVHUPTb/ZareenFava.png" alt="" />
    <div class="artist-overlay">
      <h1>Zareen Fava</h1>
    
    </div>
  </div>
</a>

<a href="https://twitter.com/ioan1x" target="_blank" rel="noreferrer">
  <div class="mentor-unit C5 fill">
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/aleksandra%20art.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Aleksandra Art</h1>
    </div>
  </div>
</a>

<a href="https://twitter.com/AlexEstorick" target="_blank" rel="noreferrer">
  <div class="mentor-unit C5 fill">
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/alex%20estorick.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Alex Estorick</h1>
    </div>
  </div>
</a>

<a href="https://twitter.com/CaballeroAnaMa" target="_blank" rel="noreferrer">
  <div class="mentor-unit C5 fill">
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/ana%20maria%20caballero.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Ana Maria Caballero</h1>
    </div>
  </div>
</a>

<a href="https://twitter.com/DanielKantor_1" target="_blank" rel="noreferrer">
  <div class="mentor-unit C5 fill">
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/daniel%20kantor.jpg" alt="" />
    <div class="artist-overlay">
      <h1>Daniel Kantor</h1>
    </div>
  </div>
</a>

<a href="https://twitter.com/IndiaPrice99" target="_blank" rel="noreferrer">
  <div class="mentor-unit C5 fill">
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/India%20Price.jpg" alt="" />
    <div class="artist-overlay">
      <h1>India Price</h1>
    </div>
  </div>
</a>

<a href="https://twitter.com/VicDoval" target="_blank" rel="noreferrer">
  <div class="mentor-unit C5 fill">
  <img src="https://verticalcrypto.mypinata.cloud/ipfs/QmNepNHMf62t8CoGCg3gnZVg35kbDxTh382D3nVQXDhJUc/Víctor%20Doval.jpeg" alt="" />
    <div class="artist-overlay">
      <h1>Victor Doval</h1>
    </div>
  </div>
</a>

{modalOpen.bool && <Modal setOpenModal={setModalOpen} mentorId={modalOpen.mentorId}/>}

</div>
    </section>
)}